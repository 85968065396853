/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

@media screen and (min-device-width: 1201px) {}

@media screen and (min-device-width: 992px) {
    .AppHeader .navbar-expand-lg .offcanvas {
        display: none;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
    
    .middle .presaleCounter {
        border-radius: 29px;
    }

    .middle .counterContainer .wrapper,
    .middle .counterContainer .wrapper .flex,
    .App .presaleCounter .itemWrapper {
        width: 100%;
    }

    .App .presaleCounter .itemWrapper {
        padding: 20px 5px;
    }



    .Profile .ProfileWrapper {
        flex-direction: column;
    }

    .Profile .inputStyles :is(input) {
        padding: 14px 0;
    }

    .Profile.InnerScreens .inputSize {
        justify-content: space-between;
    }

    .App .Profile.InnerScreens .datePickerWrapper,
    .App .Profile.InnerScreens .numberWrapper {
        margin: 0 !important;
    }

    .App .Profile.InnerScreens .inputStyles,
    .Profile .inputStyles.marginFields,
    .App .Profile.InnerScreens .inputStyles.nationalityWrapper {
        margin: 5px 0;
        padding-left: 0;
        padding-right: 0;
    }

    .InnerScreens .inputStyles :is(label, input) {
        font-size: 14px;
    }

    .InnerScreens.Profile .tabs,
    .InnerScreens.Profile .tab {
        /* min-height: 40px;
        height: 40px;
        min-width: 130px;
        font-size: 10px; */
    }

    .InnerScreens.Profile .numberWrapper .languageWrapperDiv {
        padding: 12px 3px;
    }

    .InnerScreens.Profile .currentStatusWrapper p {
        font-size: 16px;
        padding-right: 10px;
    }

    .Profile .secondWrapperPFlex {
        justify-content: center;
    }

    .secondWrapperPFlex {
        padding-top: 20px;
    }

    .Profile.InnerScreens label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
        margin: 0;
    }

    .Profile .twoFactorPara {
        margin: 0;
        font-size: 14px;
    }

    .InnerScreens.Profile .currentStatusWrapper {
        flex-wrap: wrap;
    }

    .InnerScreens.Profile .fieldset {
        margin-bottom: 10px;
        padding: 0;
    }

    :is(.BuyToken, .Profile).InnerScreens .whiteBgWrapper {
        padding: 25px;
    }

    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper,
    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper .emptyWrapper {
        padding: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper:first-child {
        /* margin: 0;
        margin-bottom: 20px; */
    }

    .App .InnerScreens .screenWrapper {
        border-radius: 9px;
    }

    .Profile.InnerScreens :is(.ProfileButton, .ProfileButton2, .ProfileButton3) {
        font-size: 16px;
        padding: 12px 50px;
        width: fit-content;
        margin-top: 20px;
    }

    .Profile .secondWrapper {
        /* flex-wrap: wrap; */
        /* margin: 0; */
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper {
        /* flex: none;
        width: 100%; */
    }

    .Profile.InnerScreens .inputSize {
        flex-wrap: wrap;
    }

    .Profile.InnerScreens .inputSize :is(.inputStyles.marginFields, .datePickerWrapper, .numberWrapper.inputStyles),
    .Profile.InnerScreens .inputStyles.nationalityWrapper {
        width: 48%;
    }

    .InnerScreens.Profile .whiteBgWrapper p.title {
        /* font-size: 26px; */
    }




    .BuyToken .inputWrapper.col-lg-8 {
        width: 100%
    }

    .BuyToken.InnerScreens .emptyWrapper>.whiteBgWrapper {
        padding: 25px
    }

    .App .MyToken .whiteBgWrapper {
        flex-direction: column;
    }

    .InnerScreens.MyToken .divider {
        width: 100%;
        height: 2px;
        color: #9CE8F1;
        background: #9CE8F1;
        margin: 20px 0;
    }

    .App .InnerScreens .header {
        padding: 35px 30px 25px;
    }

    .InnerScreens .whiteBgWrapper {
        padding: 25px 0;
    }

    .InnerScreens .sideBar img.logo {
        height: 65px;
    }

    .InnerScreens .sideBar .userImgBox {
        padding: 25px;
    }

    .InnerScreens .sideBar img.userImg {
        height: 70px;
        width: 70px;
    }

    .InnerScreens .sideBar ul a {
        padding: 0px 35px;
    }

    .App .InnerScreens {
        padding: 2rem 0 2rem 2rem;
    }

    .authWrapper .form {
        padding: 50px;
    }

    .AppScreensHeader2 {
        display: none;
    }

    .AccountRecovery .authParaText,
    .CreateNewPassword .authParaText {
        padding: 30px 0;
        font-size: 18px;
        line-height: normal;
    }

    .CreateNewPassword .css-1jv9pqf-MuiTypography-root {
        padding-top: 15px;
    }

    /* --------------------header-------------------- */

    .navBarIcon {
        display: none;
    }

    /* --------------------header-------------------- */

    /* --------------------inner screens-------------------- */

    .waveMapImage {
        height: 400px;
    }

    .Profile .tab {
        min-width: auto;
        display: flex;
        flex: 1;
        padding: 10px 5px;
        /* font-size: 8px; */
    }

    .firstWrapper,
    .secondWrapper {
        flex: 1;
    }

    .MyToken .whiteBgWrapper .flex {
        flex-wrap: wrap;
    }

    .Transactions .contentContainer {
        width: 60%;
    }

    .Transactions .dataTables_wrapper .row:first-child {
        right: 10%;
    }

    /* --------------------inner screens-------------------- */

    /* --------------------auth screens-------------------- */
    .authWrapper .col-lg-6 {
        /* min-width: 65%;
        width: 65%; */
    }

    /* --------------------auth screens-------------------- */

}

@media screen and (max-device-width: 1024px) {
    .navBarIcon {
        display: inline;
    }

    #basic-navbar-nav {
        display: none;
    }
}

@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
    .App .counterContainer {
        padding-bottom: 0;
    }
    .App .middle {
        margin-top: 40px;
    }

    .middle .presaleCounter {
        border-radius: 29px;
    }

    .App .middle .heading-lg {
        font-size: 35px;
    }

    .middle .counterContainer .wrapper {
        width: 100%;
    }

    .middle .counterContainer .wrapper .flex {
        width: 100%;    }

    .App .presaleCounter .itemWrapper .name {
        font-size: 20px;
    }

    .App .presaleCounter .itemWrapper .counterText {
        font-size: 45px;
    }

    .App .presaleCounter .itemWrapper {
        width: 100%;
        padding: 20px 5px;
    }




    .Profile .inputStyles :is(input) {
        padding: 14px 0;
    }

    .Profile.InnerScreens .inputSize {
        justify-content: space-between;
    }

    .App .Profile.InnerScreens .datePickerWrapper,
    .App .Profile.InnerScreens .numberWrapper {
        margin: 0 !important;
    }

    .App .Profile.InnerScreens .inputStyles,
    .Profile .inputStyles.marginFields,
    .App .Profile.InnerScreens .inputStyles.nationalityWrapper {
        margin: 5px 0;
        padding-left: 0;
        padding-right: 0;
    }

    .InnerScreens .inputStyles :is(label, input) {
        font-size: 14px;
    }

    .InnerScreens.Profile .numberWrapper .languageWrapperDiv {
        padding: 12px 3px;
    }

    .InnerScreens.Profile .currentStatusWrapper p {
        font-size: 16px;
        padding-right: 10px;
    }

    .Profile .secondWrapperPFlex {
        justify-content: center;
    }

    .secondWrapperPFlex {
        padding-top: 20px;
    }

    .Profile.InnerScreens label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
        margin: 0;
    }

    .Profile .twoFactorPara {
        margin: 0;
        font-size: 14px;
    }

    .InnerScreens.Profile .currentStatusWrapper {
        flex-wrap: wrap;
    }

    .InnerScreens.Profile .fieldset {
        margin-bottom: 10px;
        padding: 0;
    }

    :is(.BuyToken, .Profile) .whiteBgWrapper {
        padding: 25px;
    }

    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper,
    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper .emptyWrapper {
        padding: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper:first-child {
        margin: 0;
        margin-bottom: 20px;
    }

    .App .InnerScreens .screenWrapper {
        border-radius: 9px;
    }

    .Profile.InnerScreens :is(.ProfileButton, .ProfileButton2, .ProfileButton3) {
        font-size: 16px;
        padding: 12px 50px;
        width: fit-content;
        margin-top: 20px;
    }

    .Profile .secondWrapper {
        flex-wrap: wrap;
        margin: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper {
        flex: none;
        width: 100%;
    }

    .Profile.InnerScreens .inputSize {
        flex-wrap: wrap;
    }

    .Profile.InnerScreens .inputSize :is(.inputStyles.marginFields, .datePickerWrapper, .numberWrapper.inputStyles),
    .Profile.InnerScreens .inputStyles.nationalityWrapper {
        width: 48%;
    }

    .InnerScreens.Profile .whiteBgWrapper p.title {
        /* font-size: 26px; */
    }




    .BuyToken .inputWrapper.col-lg-8 {
        width: 100%
    }

    .App .MyToken .whiteBgWrapper {
        flex-direction: column;
    }

    .InnerScreens.MyToken .divider {
        width: 100%;
        height: 2px;
        color: #9CE8F1;
        background: #9CE8F1;
        margin: 20px 0;
    }

    .AppScreensHeader2 {
        display: none;
    }

    .InnerScreens .sideBar img.logo {
        height: 65px;
        margin-bottom: 30px;
    }

    .InnerScreens .sideBar img.userImg {
        height: 65px;
        width: 65px;
    }

    .InnerScreens .sideBar .userImgBox {
        padding: 25px 2rem;
    }

    .InnerScreens .sideBar .userImgBox span {
        font-size: 20px;
    }

    .InnerScreens .sideBar .userImgBox span:nth-child(2) {
        padding-top: 5px;
    }

    .InnerScreens .sideBar ul a {
        padding: 0 2rem;
    }

    .InnerScreens .sideBar ul a span,
    .InnerScreens .sideBar .signOut a span {
        padding: 20px 0px;
        font-size: 18px;
    }

    .App .InnerScreens {
        padding: 2rem 0 2rem 2rem;
    }

    .AppScreensHeader2 {
        padding: 0;
    }

    .Login .authWrapper {
        flex-direction: column;
        justify-content: flex-start;
    }

    .Login .screenLogo {
        position: relative;
        left: unset;
        margin-bottom: 20px;
    }

    .CreateNewPassword .css-1jv9pqf-MuiTypography-root,
    .CreateNewPassword .authParaText {
        font-size: 20px;
        padding-top: 10px;
        line-height: 32px;
    }

    .authWrapper .col-lg-6 {
        width: 76%;
    }

    /* --------------------auth screens-------------------- */
    .authWrapper .col-lg-6 {
        /* min-width: 70%;
        width: 70%; */
    }

    .AppHeader.AppLogoHeader.container {
        margin: 0;
        max-width: 100%;
    }

    /* --------------------auth screens-------------------- */

    /* --------------------inner screens-------------------- */
    .MyToken .emptyWrapper {
        padding: 0;
    }

    .MyToken .whiteBgWrapper .flex.wrapperWithBtn {
        /* flex-direction: column;
        align-items: flex-start; */
    }

    .MyToken .whiteBgWrapper .flex.wrapperWithBtn .AppScreensButtonSecondary {
        margin-top: 15px;
        line-height: normal;
        width: fit-content;
        padding: 10px;
        font-size: 16px;
    }

    .Transactions .dataTables_wrapper .row:first-child {
        right: 14%;
    }

    .Transactions .contentContainer {
        width: 60%;
    }

    .BuyToken .whiteBgWrapper {
        min-width: 100%;
    }

    .firstWrapper,
    .secondWrapper .whiteBgWrapper:first-child {
        margin-bottom: 20px;
        min-width: 100%;
    }

    .InnerScreens .sideBar {
        flex: 1.2
    }

    .MyToken .whiteBgWrapper:first-child {
        margin-right: 12px;
    }

    .InnerScreens .MyToken .whiteBgWrapper {
        margin: 0 12px 20px;
    }


    .InnerScreens .header h5,
    .MyToken .wrapper h3 {
        font-size: 25px;
    }

    .MyToken .wrapper h2 {
        font-size: 30px;
    }

    .secondWrapper {
        padding: 0;
    }

    .Profile .emptyWrapper {
        flex-wrap: wrap;
    }

    .Profile .tab {
        min-width: auto;
        display: flex;
        flex: 1;
        padding: 10px 5px;
        /* font-size: 8px; */
    }

    /* --------------------inner screens-------------------- */


    /* --------------------outer screens-------------------- */

    .paraWrapper {
        width: auto;
        padding: 8px 30px;
    }

    :is(.AboutUs, .WaveMap) .paraWrapper {
        width: 70%;
    }

    .tokenomicsGrid .paraWrapper {
        padding: 8px 0;
    }

    .tokenomicsWrapper .textImgGridContainer {
        width: calc(100% * 0.85);
    }

    .stickyHeader .navbarContainer {
        right: 0;
    }

    .percentageWrapper {
        margin-bottom: 50px;
    }

    .presaleCounter .counterWrapper {
        margin-top: 5px;
    }

    /* --------------------outer screens-------------------- */

}

@media screen and (min-device-width: 769px) and (max-device-width: 899px) {

    /* --------------------auth screens-------------------- */

    /* --------------------auth screens-------------------- */

    /* --------------------outer screens-------------------- */
    .tokenomicsGrid {
        width: 50%;
        margin: 0 auto 50px
    }

    .custom-select-wrapper ul {
        width: auto;
    }

    /* --------------------outer screens-------------------- */

    /* --------------------inner screens-------------------- */
    .Transactions .dataTables_wrapper .row:first-child {
        position: relative;
        top: 0;
        right: 0;
        margin: 0;
        background-color: var(--white);
        border-right: 2px solid var(--erieBlack);
        border-left: 2px solid var(--erieBlack);
        padding-right: 13px;
    }

    /* --------------------inner screens-------------------- */

}

@media screen and (min-width: 768px) and (max-width: 899px) {
    .InnerScreens .AppScreensHeader2 {
        display: inline;
    }

    .AppHeader.AppLogoHeader.container {
        margin: 0;
        max-width: 100%;
    }

    .App .InnerScreens .contentContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .AppScreensHeader2 {
        max-width: 95%;
    }

    .AppScreensHeader2 .mainContainer {
        max-width: 98%;
    }

    .AppScreensHeader2 .navbarContainer .navbar-brand img {
        height: 55px;
    }

    .AppScreensHeader2 .navbarContainer.navbar {
        width: auto;
    }

    .App .InnerScreens {
        padding: 0;
        flex-direction: column;
    }

    .App .InnerScreens :is(.relativeHeader, .stickyHeader) {
        display: inline;
    }

    .InnerScreens .sideBar {
        display: none;
    }

}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
    .App .counterContainer {
        padding-bottom: 0;
    }
    .App .middle {
        margin-top: 40px;
    }

    .middle .presaleCounter {
        border-radius: 29px;
    }

    .App .middle .heading-lg {
        font-size: 35px;
    }

    .middle .counterContainer .wrapper {
        width: 100%;
    }

    .middle .counterContainer .wrapper .flex {
        flex: 1;
    }

    .App .presaleCounter .itemWrapper .name {
        font-size: 20px;
    }

    .App .presaleCounter .itemWrapper .counterText {
        font-size: 45px;
    }

    .App .presaleCounter .itemWrapper {
        padding: 20px 0px;
        width: 100px;
    }




    .App .Profile.InnerScreens .datePickerWrapper,
    .App .Profile.InnerScreens .numberWrapper {
        margin: 0 !important;
    }

    .App .Profile.InnerScreens .inputStyles,
    .Profile .inputStyles.marginFields,
    .App .Profile.InnerScreens .inputStyles.nationalityWrapper {
        margin: 5px 0;
        padding-left: 0;
        padding-right: 0;
    }

    .InnerScreens .inputStyles :is(label, input) {
        font-size: 12px;
    }

    .InnerScreens.Profile .tabs,
    .InnerScreens.Profile .tab {
        min-height: 40px;
        height: 40px;
        min-width: 130px;
        font-size: 10px;
    }

    .InnerScreens.Profile .numberWrapper .languageWrapperDiv {
        padding: 6px 3px;
    }

    .InnerScreens.Profile .currentStatusWrapper p {
        font-size: 16px;
        padding-right: 10px;
    }

    .Profile .secondWrapperPFlex {
        justify-content: center;
    }

    .secondWrapperPFlex {
        padding-top: 20px;
    }

    .Profile.InnerScreens label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
        margin: 0;
    }

    .Profile .twoFactorPara {
        margin: 0;
        font-size: 14px;
    }

    .InnerScreens.Profile .currentStatusWrapper {
        flex-wrap: wrap;
    }

    .InnerScreens.Profile .fieldset {
        margin-bottom: 10px;
        padding: 0;
    }

    :is(.BuyToken, .Profile) .whiteBgWrapper {
        padding: 25px;
    }

    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper,
    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper .emptyWrapper {
        padding: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper:first-child {
        margin: 0;
        margin-bottom: 20px;
    }

    .App .InnerScreens .screenWrapper {
        border-radius: 9px;
    }

    .Profile.InnerScreens :is(.ProfileButton, .ProfileButton2, .ProfileButton3) {
        font-size: 14px;
        padding: 12px 50px;
        width: fit-content;
        margin-top: 20px;
    }

    .Profile .secondWrapper {
        flex-wrap: wrap;
        margin: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper {
        flex: none;
        width: 100%;
    }

    .Profile.InnerScreens .inputSize {
        flex-wrap: wrap;
    }

    .Profile.InnerScreens .inputSize :is(.inputStyles.marginFields, .datePickerWrapper, .numberWrapper.inputStyles),
    .Profile.InnerScreens .inputStyles.nationalityWrapper {
        width: 100%;
    }

    .InnerScreens.Profile .whiteBgWrapper p.title {
        font-size: 26px;
    }

    .BuyToken .buyTokenForm .amountWrapper {
        margin: 20px 0 40px;
        flex-wrap: wrap;
    }

    .BuyToken .TokenButton.widthAuto {
        justify-content: center;
    }

    .BuyToken .formInnerContent :is(span, a) {
        font-size: 14px;
    }

    .BuyToken .buyTokenForm .amountWrapper .amount {
        width: 50%;
        flex: unset;
        padding-top: 20px;
        justify-content: flex-start;
    }

    .BuyToken .buyTokenForm .amountWrapper div:first-child {
        width: 100%;
        flex: unset;
    }

    .BuyToken .whiteBgWrapper p.title {
        margin: 0;
    }



    .App .InnerScreens.Transactions .header h5 {
        text-align: left;
    }

    .InnerScreens.MyToken .divider {
        width: 100%;
        height: 2px;
        color: #9CE8F1;
        background: #9CE8F1;
        margin: 20px 0;
    }

    .App .MyToken .whiteBgWrapper {
        flex-direction: column;
    }

    .InnerScreens .AppScreensHeader2 {
        display: inline;
    }

    .App .InnerScreens .contentContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .AppScreensHeader2 .mainContainer {
        max-width: 95%;
    }

    .App .InnerScreens {
        padding: 0;
        flex-direction: column;
    }

    .chart.whiteBgWrapper {
        padding: 25px 0;
    }

    .CreateNewPassword .authWrapper .authParaText {
        font-size: 18px;
    }

    .CreateNewPassword .css-lmtw0u-MuiTypography-root {
        font-size: 28px;
    }

    .CreateNewPassword .css-1jv9pqf-MuiTypography-root {
        font-size: 16px;
        padding-top: 20px;
        line-height: normal;
    }

    .Register .formInnerContent.rememberMeWrapper {
        flex-wrap: nowrap;
    }

    .Register .formInnerContent.rememberMeWrapper span {
        font-size: 12px;
    }

    .Login .formInnerContent a.forgotText.registerNowText,
    .CreateNewPassword .authParaText {
        font-size: 20px;
    }

    .Login .AppScreensButton {
        text-align: center;
        justify-content: center;
        font-size: 14px;
    }

    button[aria-label="toggle password visibility"] svg {
        height: 18px;
        width: 18px;
    }

    .formInnerContent.rememberMeWrapper svg {
        height: 20px;
        width: 20px;
    }

    .Login :is(.inputStyles :is(label, input),
        .formInnerContent :is(span, a),
        .formInnerContent a.forgotText) {
        font-size: 14px;
    }

    .authWrapper {
        flex-direction: column;
        justify-content: flex-start;
    }

    .Login .screenLogo {
        position: relative;
        top: unset;
        left: unset;
        margin-bottom: 20px;
        height: 4rem;

    }

    .Login .bgImage1 {
        background-image: url('../images/mobileBgLogin2.png');
        height: 50%;
        width: 100%;
    }

    .Login .bgImage2 {
        background-image: url('../images/mobileBgLogin.png');
        height: 100%;
        width: 100%;
    }

    :is(.AccountRecovery, .CreateNewPassword) .loginFormWrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    /* --------------------auth screens-------------------- */
    .AppHeader.AppLogoHeader.container {
        margin: 0;
        max-width: 100%;
    }


    .AppLogoHeader .navbarContainer .container {
        margin: 0;
    }

    .authWrapper .col-lg-6 {
        padding-bottom: 40px;
        width: 85%;
    }

    :is(.AccountRecovery, .CreateNewPassword) .loginFormWrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .Login .screenWrapper {
        padding: 0;
    }

    .authWrapper .form {
        padding: 35px;
    }

    .Login.WaveMap .contentWrapper {
        display: inline;
    }

    .authWrapper .authParaText {
        padding-left: 0;
        margin: 0;
    }

    .loginForm {
        padding: 40px 0;
    }


    .Login label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 16.5px 14px;
    }

    .dontHaveAccWrapper {
        padding: 40px 0;
    }

    /* --------------------auth screens-------------------- */

    /* --------------------inner screens-------------------- */
    .firstWrapper {
        min-width: 100%;
    }

    .MyToken .screenFlexWrapper {
        flex-direction: column;
    }

    .MyToken .screenFlexWrapper .whiteBgWrapper>.flex.wrapper:first-child {
        flex-wrap: wrap;
    }

    .MyToken .whiteBgWrapper .group {
        flex-wrap: wrap;
    }

    .MyToken .wrapper h3 {
        font-size: 25px;
    }

    .MyToken .wrapper h2 {
        font-size: 30px;
    }

    .App .MyToken .wrapper .AppScreensButtonSecondary {
        margin: 20px 0;
        width: fit-content;
        font-size: 16px;
        line-height: normal;

    }

    .MyToken .whiteBgWrapper:first-child {
        margin: 0;
    }

    .secondWrapper {
        padding-left: 0;
        padding-top: 25px;
    }

    .InnerScreens .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, 14px) scale(1);
    }

    .Profile .inputStyles,
    .Profile .inputStyles.marginFields,
    .Profile .inputStyles.nationalityWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .profileForm {
        padding: 30px 0;
    }

    .Profile .tab {
        min-width: auto;
        display: flex;
        flex: 1;
        padding: 10px 5px;
    }

    .Transactions .dataTable_scrollBody::-webkit-scrollbar {
        width: 0;
    }

    .Transactions :is(th, td) {
        padding: 14px 10px;
    }

    .Transactions .dataTables_paginate .pagination {
        justify-content: center;
    }

    .Transactions .mdb-datatable-filter {
        width: 100%;
    }

    .Transactions .dataTables_wrapper .row:first-child {
        top: 25px;
        right: 15%;
    }

    .whiteBgWrapper p.title {
        width: 100%;
    }

    .Transactions .dataTables_wrapper .row:nth-child(2) :is([class="dataTables_scroll"]) {
        padding: 0;
    }

    .Transactions :is(.dataTables_scrollHead, .dataTable_scrollBody) {
        padding: 0 20px;
    }

    .Transactions .wrapper {
        padding: 20px;
    }

    .AppScreensButtonSecondary {
        width: 100%;
    }

    .buyTokenForm .amountWrapper .amount p,
    .fieldset span {
        font-size: 14px;
    }

    .inputWrapper .buyTokenForm .amountWrapper .amount:first-child {
        padding-left: 0;
    }

    .inputWrapper .buyTokenForm .amountWrapper .amount:last-child {
        padding-right: 0;
    }

    .InnerScreens .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
    .fieldset,
    .InnerScreens .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
    label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 14px 14px;
    }

    .InnerScreens .inputWrapper label,
    .inputStyles :is(label, input) {
        font-size: 14px;
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, -7px) scale(0.75);
    }

    .InnerScreens #outlined-select-currency {
        padding: 14px 30px 14px 15px;
    }

    .InnerScreens #outlined-select-currency,
    .css-6hp17o-MuiList-root-MuiMenu-list .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .formInnerContent :is(span, a),
    .Transactions :is(th, td) {
        font-size: 14px;
    }

    .whiteBgWrapper {
        padding: 20px;
    }

    .emptyWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .ProfileWrapper.InnerScreens {
        padding: 0;
    }

    .App .InnerScreens .screenWrapper {
        background-image: none;
        padding: 20px 5px;
    }

    .App .InnerScreens :is(.relativeHeader, .stickyHeader) {
        display: inline;
    }

    .App .InnerScreens .header {
        justify-content: center;
    }

    .App .InnerScreens .header h5 {
        text-align: center;
    }

    .InnerScreens .header .container {
        padding: 0;
    }

    .InnerScreens .header h5 {
        font-size: 22px;
    }

    .AppScreensHeader2 .navbarContainer .navbar-brand img {
        height: 55px;
    }

    .AppScreensHeader2Canvas .sideBar .navLink div.imageWrapper {
        width: 40px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .flex span {
        font-size: 14px;
        padding-left: 15px;
    }

    .footerImgWrapper {
        padding: 40px 0 20px;
    }

    .AppScreensHeader2Canvas .sideBar {
        padding: 10px 0;
        height: 100%;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .signOut a {
        justify-content: flex-start;
        padding: 0 40px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .signOut {
        padding-top: 30px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .flex {
        flex-direction: row;
        padding: 0 40px;
        justify-content: flex-start;
        height: auto;
    }

    .AppScreensHeader2Canvas .footerImgWrapper img.userImg {
        margin: 0;
        height: 45px;
        width: 45px;
    }

    .AppScreensHeader2Canvas .sideBar ul {
        padding: 0;
        margin: 0;
    }

    .AppScreensHeader2Canvas .sideBar ul a img,
    .AppScreensHeader2Canvas .footerImgWrapper .signOut img {
        height: 22px;
    }

    .AppScreensHeader2Canvas .sideBar ul a span,
    .AppScreensHeader2Canvas .footerImgWrapper .signOut a span {
        font-size: 14px;
        font-weight: 600;
    }

    .offcanvas .sideBar ul a.navLink {
        justify-content: flex-start;
        padding: 10px 40px;
        margin: 30px 0;
    }

    .AppScreensHeader2Canvas .offcanvas-header {
        background-color: var(--white);
        padding: 20px 20px;
    }

    .AppScreensHeader2Canvas .offcanvas-header img {
        height: 30px;
    }

    .InnerScreens .sideBar {
        display: none;
    }

    /* --------------------inner screens-------------------- */
















    /* --------------------outer screens-------------------- */
    .custom-select-wrapper ul {
        width: auto;
    }

    .screenWrapper video~.stickeyHeader.container,
    .screenWrapper video~.relativeHeader.container,
    .screenContainer,
    .AppHeader,
    .AppFooter>.container,
    .AppHeader2 {
        max-width: 100%;
    }

    .screenWrapper {
        padding: 0
    }

    .tokenomicsWrapper .textImgGridContainer.gridWrapper {
        width: auto;
    }

    .paraWrapper,
    .textImgGridContainer,
    :is(.textGridWrapper, .imgGridWrapper) {
        width: auto;
        padding: 8px 20px;
    }

    .AppFooter .image {
        height: 30px;
    }

    .counterWrapper {
        padding: 20px;
    }

    .separator {
        margin: 0 20px;
    }

    .counterText {
        font-size: 30px;
    }

    .AppHeader2 .contentWrapper,
    .textImgGridContainer {
        padding: 50px 20px;
    }

    .textImgGridContainer {
        flex-direction: column !important;
    }

    .imgGridWrapper .image {
        width: 160px;
        height: 160px;
        margin-top: 30px;
    }

    .tokenomicsWrapper .textImgGridContainer .flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    .pieChart {
        margin-bottom: 40px;
    }

    .tokenomicsGrid {
        width: 80%;
        margin: 25px auto;
    }

    .stickyHeader {
        right: 0;
    }

    .offcanvas.show {
        width: 75%;
    }

    .btnWrapper {
        padding: 20px 0;
        flex-wrap: wrap;
    }

    .btnWrapper .btn {
        width: 40%;
        margin: 0;
        font-weight: 500;
        font-size: 14px;
    }

    .space {
        padding: 8px
    }

    .AppButton:hover {
        transform: scale(1.05);
        box-shadow: inset 2px 2px 2px 0px rgb(115 211 234 / 50%), 2px 2px 10px 0px rgb(115 211 234 / 5%), 4px 4px 5px 0px rgb(115 211 234 / 5%);
    }

    .AppFooter .image {
        height: 30px;
    }

    .AppFooter .whiteSpaceBox {
        padding: 20px;
    }

    .tokenomicsWrapper .textImgGridContainer {
        margin-top: 0;
        margin-bottom: 0;
    }

    .card1:hover:before {
        transform: scale(30);
    }

    .contentWrapper {
        padding-bottom: 0;
    }

    .percentageWrapper .textImgGridContainer {
        height: auto;
        padding: 30px 0;
    }

    .percentageWrapper {
        margin: 0;
    }

    .pieChartImg {
        position: relative;
    }

    .waveMapImage {
        height: 250px;
    }

    /* --------------------outer screens-------------------- */

}

@media screen and (min-device-width: 250px) and (max-device-width: 480px) {
    .middle .counterContainer {
        padding-bottom: 0;
    }

    .App .middle {
        padding-top: 0px;
        margin-top: 40px;
    }

    .middle .presaleCounter {
        border-radius: 29px;
        padding: 40px 20px;
    }

    .App .presaleCounter .separator {
        width: 140px;
        height: 2px;
    }

    .App .middle .heading-lg {
        font-size: 24px;
    }

    .middle .counterContainer .wrapper {
        flex-direction: column;
        width: 100%;
    }

    .middle .counterContainer .wrapper .flex {
        flex-direction: column;
    }

    .middle .presaleCounter .itemWrapper {
        width: 100%;
        padding: 40px 0;
    }

    .middle .presaleCounter .wrapper .flex:last-child .itemWrapper {
        border-bottom: none
    }

    .App .presaleCounter .itemWrapper .name {
        font-size: 20px;
    }

    .App .presaleCounter .itemWrapper .counterText {
        font-size: 45px;
        line-height: normal;
    }





    .Transactions .settingIconDiv {
        padding-right: 0px;
    }

    .InnerScreens.Profile .numberWrapper .languageWrapperDiv {
        padding: 6px 3px;
    }

    .InnerScreens.Profile .currentStatusWrapper p {
        font-size: 16px;
        padding-right: 10px;
    }

    .Profile .secondWrapperPFlex {
        justify-content: center;
    }

    .secondWrapperPFlex {
        padding-top: 20px;
    }

    .Profile.InnerScreens label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
        margin: 0;
    }

    .Profile .twoFactorPara {
        margin: 0;
        font-size: 14px;
    }

    .InnerScreens.Profile .currentStatusWrapper {
        flex-wrap: wrap;
    }

    .InnerScreens.Profile .fieldset {
        margin-bottom: 10px;
        padding: 0;
    }

    :is(.BuyToken, .Profile) .whiteBgWrapper {
        padding: 15px;
    }

    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper,
    .App :is(.Dashboard, .BuyToken, .MyToken, .Transactions, .Profile).InnerScreens .screenWrapper .emptyWrapper {
        padding: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper:first-child {
        margin: 0;
        margin-bottom: 20px;
    }

    .App .InnerScreens .screenWrapper {
        border-radius: 9px;
    }

    .Profile.InnerScreens :is(.ProfileButton, .ProfileButton2, .ProfileButton3) {
        font-size: 14px;
        padding: 12px 0px;
        width: 100%;
        margin-top: 20px;
    }

    .Profile .secondWrapper {
        flex-wrap: wrap;
        margin: 0;
    }

    .Profile.InnerScreens .secondWrapper .whiteBgWrapper {
        flex: none;
        width: 100%;
    }

    .Profile.InnerScreens .inputSize {
        flex-wrap: wrap;
    }

    .Profile.InnerScreens .inputSize :is(.inputStyles.marginFields, .datePickerWrapper, .numberWrapper.inputStyles),
    .Profile.InnerScreens .inputStyles.nationalityWrapper {
        width: 100%;
    }

    .InnerScreens.Profile .whiteBgWrapper p.title {
        font-size: 22px;
    }

    .BuyToken .buyTokenForm .amountWrapper {
        margin: 20px 0 40px;
        flex-wrap: wrap;
    }

    .BuyToken .TokenButton.widthAuto {
        width: 100% !important;
        justify-content: center;
    }

    .BuyToken .formInnerContent :is(span, a) {
        font-size: 12px !important;
    }

    .BuyToken .buyTokenForm .amountWrapper .amount {
        width: 50%;
        flex: unset;
        padding-top: 20px;
        justify-content: flex-start;
    }

    .BuyToken .buyTokenForm .amountWrapper .amount p {
        font-size: 14px;
    }

    .BuyToken .buyTokenForm .amountWrapper div:first-child {
        width: 100%;
        flex: unset;
    }

    .BuyToken .whiteBgWrapper p.title {
        margin: 0;
    }




    .App .InnerScreens.Transactions .header h5 {
        text-align: left;
    }

    .Transactions .page-link {
        font-size: 10px;
    }

    .InnerScreens.Transactions .page-item.active .page-link {
        padding: 0px 10px;
    }

    .InnerScreens.Transactions :is(.page-item:first-child, .page-item:last-child) span {
        font-size: 10px;
        padding: 3px 10px;
    }

    .InnerScreens.MyToken .divider {
        width: 100%;
        height: 2px;
        color: #9CE8F1;
        background: #9CE8F1;
        margin: 20px 0;
    }

    .MyToken .whiteBgWrapper {
        flex-direction: column;
    }

    .InnerScreens .AppScreensHeader2 {
        display: inline;
    }

    .App .InnerScreens .contentContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    .AppScreensHeader2 .mainContainer {
        max-width: 95%;
    }

    .App .InnerScreens {
        padding: 0;
        flex-direction: column;
    }

    .chart.whiteBgWrapper {
        padding: 25px 0;
    }

    /* --------------------auth screens-------------------- */
    .Login.Register .icon {
        font-size: 24px;
    }

    .authWrapper .col-lg-6 {
        padding-bottom: 40px;
    }

    .CreateNewPassword .css-lmtw0u-MuiTypography-root {
        font-size: 28px;
    }

    .CreateNewPassword .css-1jv9pqf-MuiTypography-root {
        font-size: 16px;
        padding-top: 20px;
        line-height: normal;
    }

    .Login:is(.AccountRecovery, .CreateNewPassword) .AppScreensButton {
        width: 100% !important;
    }

    .Register .bottomContentWrapper span:nth-child(2) {
        padding: 0;
    }

    .Register .socialIcon~.bottomContentWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Register .formInnerContent.rememberMeWrapper {
        flex-wrap: nowrap;
    }

    .Register .formInnerContent.rememberMeWrapper span {
        font-size: 12px;
    }

    .Login .dontHaveAccWrapper span:first-child {
        padding-right: 0 !important;
    }

    .Login .formInnerContent a.forgotText.registerNowText,
    .CreateNewPassword .authParaText {
        font-size: 20px;
    }

    .Login .AppScreensButton {
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 12px 0;
        font-size: 14px;
    }

    button[aria-label="toggle password visibility"] svg {
        height: 18px;
        width: 18px;
    }

    .formInnerContent.rememberMeWrapper svg {
        height: 20px;
        width: 20px;
    }

    .Login :is(.inputStyles :is(label, input),
        .formInnerContent :is(span, a),
        .formInnerContent a.forgotText) {
        font-size: 14px;
    }

    .Login .formInnerContent {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-bottom: 0;
    }

    .formInnerContent.rememberMeWrapper {
        justify-content: flex-start;
        width: 100%;
    }

    .authWrapper {
        flex-direction: column;
        justify-content: flex-start;
    }

    .Login .screenLogo {
        position: relative;
        top: unset;
        left: unset;
        margin-bottom: 20px;
        height: 4rem;

    }

    .Login .bgImage1 {
        background-image: url('../images/mobileBgLogin2.png');
        height: 50%;
        width: 100%;
    }

    .Login .bgImage2 {
        background-image: url('../images/mobileBgLogin.png');
        height: 100%;
        width: 100%;
    }

    .authWrapper .col-lg-6 {
        /* min-width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column; */
    }

    :is(.AccountRecovery, .CreateNewPassword) .loginFormWrapper {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        flex: 1;
    }

    .Login .screenWrapper {
        /* padding: 0; */
    }

    .authWrapper .form {
        padding: 35px 20px;
    }

    .Login.WaveMap .contentWrapper {
        display: inline;
    }

    .authWrapper .authParaText {
        padding-left: 0;
        font-size: 40px;
    }

    .loginForm {
        padding: 0px 0 20px;
    }

    .loginForm~.reveal.fade-right {
        /* padding: 0; */
    }

    .Login label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 16.5px 14px;
    }

    .dontHaveAccWrapper {
        padding: 40px 0;
    }

    /* --------------------auth screens-------------------- */


    /* --------------------inner screens-------------------- */
    .MyToken .screenFlexWrapper {
        flex-direction: column;
    }

    .MyToken .screenFlexWrapper .whiteBgWrapper>.flex.wrapper:first-child {
        width: 100%;
    }

    .MyToken .whiteBgWrapper .group {
        flex-direction: column;
        align-items: flex-start;
    }

    .MyToken .whiteBgWrapper .group div:nth-child(2) {
        padding: 5px 0;
    }

    .MyToken .wrapper h3 {
        font-size: 25px;
    }

    .MyToken .wrapper h2 {
        font-size: 30px;
    }

    .InnerScreens.MyToken .wrapper .AppScreensButtonSecondary {
        margin: 20px 0;
        width: 100%;
        padding: 10px;
        font-size: 15px;
        line-height: normal;
        text-align: center;
        justify-content: center;
        align-items: center;

    }

    .MyToken .whiteBgWrapper:first-child {
        margin: 0;
        padding: 15px;
    }

    .MyToken .whiteBgWrapper:nth-child(2) {
        padding: 15px;
    }

    .secondWrapper {
        padding-left: 0;
        padding-top: 25px;
    }

    .InnerScreens .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, 14px) scale(1);
    }

    .Profile .inputStyles,
    .Profile .inputStyles.marginFields,
    .Profile .inputStyles.nationalityWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .Profile .profileForm {
        padding: 0;
    }

    .InnerScreens.Profile .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(5px, 20px) scale(1);
    }

    .InnerScreens :is(.Profile .inputStyles, .Profile .inputStyles.marginFields, ) {
        margin: 0;
    }

    .InnerScreens .inputStyles :is(label, input) {
        font-size: 12px;
    }

    .Profile .tab {
        min-width: auto;
        display: flex;
        flex: 1;
        padding: 10px 5px;
        font-size: 8px;
    }

    .InnerScreens.Profile .tabs,
    .InnerScreens.Profile .tab {
        min-height: 36px;
        height: 36px;
        min-width: 86px;
        font-size: 8px;
    }

    .Transactions .dataTable_scrollBody::-webkit-scrollbar {
        width: 0;
    }

    .Transactions :is(th, td) {
        padding: 14px 10px;
    }

    .Transactions .dataTables_paginate .pagination {
        justify-content: center;
    }

    .Transactions .mdb-datatable-filter {
        width: 100%;
    }

    .Transactions .dataTables_wrapper .row:first-child {
        top: 3.9rem;
        right: unset;
        left: 15px;
        width: 80%;
    }

    .whiteBgWrapper p.title {
        width: 100%;
    }

    .Transactions .dataTables_wrapper .row:nth-child(2) :is([class="dataTables_scroll"]) {
        padding: 0;
    }

    .Transactions :is(.dataTables_scrollHead, .dataTable_scrollBody) {
        /* padding: 0 15px; */
    }

    .InnerScreens.Transactions .dataTables_wrapper .row:nth-child(2) :is([class="dataTables_scroll"]) {
        padding: 0 15px;
    }

    .Transactions .wrapper {
        flex-wrap: wrap;
        justify-content: end;
        padding: 15px;
    }

    .AppScreensButtonSecondary {
        width: 100%;
    }

    .buyTokenForm .amountWrapper .amount p,
    .fieldset span {
        font-size: 14px;
    }

    .buyTokenForm .amountWrapper .amount {
        flex: 1;
        padding-top: 10px;
        justify-content: space-between;
    }

    .inputWrapper .buyTokenForm .amountWrapper .amount:first-child {
        padding-left: 0;
    }

    .inputWrapper .buyTokenForm .amountWrapper .amount:last-child {
        padding-right: 0;
    }

    .InnerScreens .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
    .fieldset,
    .InnerScreens .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
    label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 12px 14px;
    }

    .InnerScreens .inputWrapper label,
    .inputStyles :is(label, input) {
        font-size: 12px;
        /* line-height: normal; */
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, -7px) scale(0.75);
    }

    .InnerScreens #outlined-select-currency {
        padding: 12px 30px 12px 15px;
    }

    .InnerScreens #outlined-select-currency,
    .css-6hp17o-MuiList-root-MuiMenu-list .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .formInnerContent :is(span, a),
    .Transactions :is(th, td) {
        font-size: 12px;
    }

    .buyTokenForm .amountWrapper div:first-child {
        width: 100%;
        flex: none;
    }

    .whiteBgWrapper {
        padding: 15px;
    }

    .emptyWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .App .InnerScreens .screenWrapper {
        background-image: none;
        padding: 0 10px;
    }

    .App .InnerScreens :is(.relativeHeader, .stickyHeader) {
        display: inline;
    }

    .App .InnerScreens .header {
        margin: 0 20px 0 0;
        padding: 20px 0px;
    }

    .InnerScreens .header .container {
        padding: 0;
    }

    .App .InnerScreens .header h5 {
        font-size: 24px;
        /* padding-top: 20px; */
        text-align: center;
    }


    .AppScreensHeader2 .navbarContainer .navbar-brand img {
        height: 45px;
    }

    .AppScreensHeader2Canvas .sideBar .navLink div.imageWrapper {
        width: 40px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .flex span {
        font-size: 14px;
        padding-left: 15px;
    }

    .footerImgWrapper {
        padding: 40px 0 20px;
    }

    .AppScreensHeader2Canvas .sideBar {
        padding: 10px 0;
        height: 100%;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .signOut a {
        justify-content: flex-start;
        padding: 0 40px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .signOut {
        padding-top: 30px;
    }

    .AppScreensHeader2Canvas .footerImgWrapper .flex {
        flex-direction: row;
        padding: 0 40px;
        justify-content: flex-start;
        height: auto;
    }

    .AppScreensHeader2Canvas .footerImgWrapper img.userImg {
        margin: 0;
        height: 45px;
        width: 45px;
    }

    .AppScreensHeader2Canvas .sideBar ul {
        padding: 0;
        margin: 0;
    }

    .AppScreensHeader2Canvas .sideBar ul a img,
    .AppScreensHeader2Canvas .footerImgWrapper .signOut img {
        height: 18px;
    }

    .AppScreensHeader2Canvas .sideBar ul a span,
    .AppScreensHeader2Canvas .footerImgWrapper .signOut a span {
        font-size: 12px;
        font-weight: 600;
    }

    .offcanvas .sideBar ul a.navLink {
        justify-content: flex-start;
        padding: 10px 40px;
        margin: 30px 0;
    }

    .AppScreensHeader2Canvas .offcanvas-header {
        background-color: var(--white);
        padding: 20px 20px;
    }

    .AppScreensHeader2Canvas .offcanvas-header img {
        height: 25px;
    }

    .InnerScreens .sideBar {
        display: none;
    }

    /* --------------------inner screens-------------------- */
















    /* --------------------outer screens-------------------- */
    .custom-select-wrapper ul {
        width: auto;
    }

    .stickyHeader {
        right: 0;
    }

    .screenWrapper {
        padding: 0
    }

    .paraWrapper {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .navbarContainer {
        padding-right: 0;
        padding-left: 0;
    }

    .btnWrapper {
        padding: 20px 0;
        flex-wrap: wrap;
    }

    .btnWrapper .btn {
        width: 48%;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
    }

    .space {
        padding: 5px
    }

    .AppButton {
        font-size: 14px;
    }

    .AppButton:hover {
        transform: scale(1.1);
        box-shadow: inset 2px 2px 2px 0px rgb(115 211 234 / 50%), 2px 2px 10px 0px rgb(115 211 234 / 5%), 4px 4px 5px 0px rgb(115 211 234 / 5%);
    }

    .counterWrapper {
        padding: 20px;
    }

    .separator {
        margin: 0 15px;
        width: 2px;
    }

    .counterText {
        font-size: 18px;
    }

    .name {
        font-size: 12px;
    }

    .counterContainer {
        padding: 20px 0;
    }

    .itemWrapper {
        width: 40px;
    }

    .counterWrapper {
        padding: 20px 10px;
    }

    .AppHeader2 .contentWrapper {
        padding: 50px 0;
    }

    .textImgGridContainer {
        flex-direction: column !important;
    }

    .tokenomicsWrapper .textImgGridContainer,
    .textImgGridContainer {
        width: 95%;
    }

    .imgGridWrapper .image {
        width: 120px;
        height: 120px;
        margin-top: 30px;
    }

    :is(.textGridWrapper, .imgGridWrapper) {
        width: 100%;
    }

    .css-eeowg1-MuiPaper-root {
        font-size: 11px !important;
    }

    .offcanvas .footerText {
        font-size: 12px;
    }

    .tokenomicsWrapper .tokenomicsGridTitle {
        font-size: 16px;
    }

    .tokenomicsGrid {
        width: 80%;
        margin: 25px auto;
    }

    .tokenomicsWrapper .textImgGridContainer .flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    .navbarContainer .navbar-brand img {
        height: 25px;
        margin-top: 2px;
    }

    .presaleCounter .itemWrapper {
        padding: 10px;
        width: 70px;
    }

    .presaleCounter .itemWrapper .name,
    .presaleCounter .itemWrapper .text {
        font-size: 8px;
        line-height: 10px;
    }

    .presaleCounter .itemWrapper .counterText {
        font-size: 20px;
        padding-top: 10px;
    }

    .presaleCounter .counterWrapper {
        margin-top: 10px;
    }

    .AppFooter .image {
        height: 30px;
    }

    .AppFooter .whiteSpaceBox {
        padding: 20px;
    }

    .AppFooter .footerText,
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.gridItems.css-azb5av-MuiPaper-root {
        font-size: 10px;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.gridItems.css-azb5av-MuiPaper-root {
        line-height: 18px;
    }

    .tokenomicsWrapper .textImgGridContainer {
        margin-top: 0;
        margin-bottom: 0;
    }

    .offcanvas.show {
        width: 90%;
    }

    .percentageWrapper .textImgGridContainer {
        height: auto;
        padding: 30px 0;
    }

    .pieChartImg {
        position: relative;
    }

    .percentageWrapper {
        margin: 0;
    }

    .waveMapImage {
        height: 150px;
    }

    /* --------------------outer screens-------------------- */
}