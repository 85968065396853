@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --font-size: 17px;
  --line-height: 1.8;
  --font-family: "Inter", sans-serif;
  --font-family-heading: "Manrope", sans-serif;
}

body {
  font-size: var(--font-size);
  font-family: var(--font-family);
  line-height: var(--line-height);
}

h1,
h2 {
  font-family: var(--font-family-heading);
  color: #000;
}

.fw-7 {
  font-weight: 700 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.navbar-nav .nav-link {
  font-size: 0.875rem;
  font-weight: 600;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: #000;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link.active {
  color: rgba(10, 10, 13, 0.4) !important;
}

.appLogo {
  height: 50px;
}

.navbar {
  background: rgb(255 255 255 / 30%) !important;
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
}

.banner {
  position: relative;
  min-height: 100vh;
  background-position: top;
  /* background-position: top left; */
  padding: 100px 0px 200px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top {
  background-image: url(../images/banner.png);
  /* border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%; */
  background-position: bottom;
  /* margin-bottom: 50px; */
  margin-top: 78px;
  padding: 0;
}

.bottom {
  background-image: url(../images/banner2.png);
  /* border-top-left-radius: 25%;
  border-top-right-radius: 25%; */
}

.divider-wrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px;
}

.divider {
  display: block;
  width: calc(110% + 1.3px);
  height: 165px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden;
  vertical-align: middle;
  background-color: #fff;
}

.heading-lg {
  font-size: 3rem;
  line-height: 1.28;
  font-weight: 800;
}

.underline {
  position: relative;
  z-index: 10;
}

.underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-color: #fbd600;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.underline:hover::after {
  height: 100%;
}

.btn {
  padding: 0.75rem 5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 14px;
  gap: 10px;
  font-weight: 600;
}

.btn-dark {
  background-color: #000;
  border-color: #000;
}

.customCard {
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px;
  padding: 0.938rem;
  animation: upDown 5s infinite;
  transition: all 0.3s ease-in-out;
}

.customCard img {
  max-width: 300px;
}

.customCard p {
  margin-left: 0.938rem;
  font-size: 1.125rem;
  line-height: 1.625rem;
  padding: 1rem;
}

.customCard[variant="light"] {
  background-color: #fff;
  color: #626262;
}

.customCard[variant="dark"] {
  background-color: #495058;
  color: #fff;
}

.counterWrapper {
  /* transform: translateY(-295px); */
  margin-top: -295px;
  margin-bottom: 100px;
}

/* .digitList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  text-align: center;
} */

.digitBox {
  display: flex;
}

.digitList p {
  font-weight: 600;
  margin-top: 1rem;
}

.digitList .dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #fbd600;
  transform: translateY(-30px);
  margin-left: 5px;
  margin-right: 10px;
}

.singleDigit {
  font-size: 3rem;
  background: #c3c3c370;
  border-radius: 10px;
  width: 85px;
  padding: 0.75rem 1.5rem;
  margin-right: 6px;
}

.why {
  position: relative;
  background-color: #021327;
  padding: 180px 0 100px 0;
  z-index: 10;
  /* overflow: hidden; */
}

.why .curve {
  position: absolute;
  top: -43px;
  left: 0;
  height: 86px;
  width: 100%;
  background-image: url(../images/greycurve.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.greyBox {
  padding: 1rem;
  background-color: #ffffff20;
  display: flex;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}

.greyBox:hover {
  box-shadow: 0px 0px 10px 0px #ffffff68;
  background-color: #021327;
}

.greyBox img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.greyBox .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
}

.greyBox .desc {
  color: #7d8dff;
}

.p-rel {
  position: relative;
}

.coin1 {
  position: absolute;
  bottom: -110px;
  width: 250px;
  left: 23vw;
  z-index: -1;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
  animation: upDown 6s infinite;
}

.coin2 {
  position: absolute;
  bottom: 0;
  width: 300px;
  left: 0;
  z-index: -1;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
  animation: upDown 6s infinite;
}

.coin3 {
  position: absolute;
  top: 57%;
  width: 52px;
  right: 0;
  z-index: -1;
}

.z-10 {
  z-index: 10;
}

.verto {
  background-image: url(../images/bg-circle-half.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 0;
}

.verto-coin {
  position: absolute;
  text-align: center;
  top: -186px;
  z-index: -1;
  left: -21px;
  width: 60%;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
  animation: upDown 6s infinite;
}

.verto .greyBox {
  background-color: #fff;
  box-shadow: 0 4px 4px 0 #00000025;
  align-items: flex-start;
  padding: 1.5rem;
  margin-bottom: 3rem !important;
}

.verto .greyBox:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%);
}

.verto .greyBox .title {
  color: #223354;
}

.verto .greyBox p {
  color: #626262;
}

.verto .greyBox:nth-child(even) {
  margin: 0px 100px 30px 50px;
}

.verto .greyBox:nth-child(odd) {
  margin: 0px 0px 30px 100px;
}

.verto .greyBox img {
  margin-top: 10px;
  margin-right: 30px;
  width: 90px;
  height: auto;
}

.about {
  background-image: url(../images/long-banner.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0;
}

.graph {
  max-width: 350px;
  margin: auto;
}

.dashboard {
  max-width: 450px;
  margin: auto;
}

.coin-image {
  max-width: 250px;
  margin: auto;
}

.gridRow {
  margin: 5rem 0;
  align-items: center;
}

.gridRow:last-child {
  margin: 0;
}

.coinWrapperLg,
.coinWrapperSm {
  position: relative;
  z-index: 10;
}

.coinWrapperSm .coin {
  position: absolute;
  top: -40px;
  left: 10%;
  max-width: 200px;
  opacity: 0.4;
  z-index: -1;
  animation: upDown 5s infinite;
  transition: all 0.3s ease-in-out;
}

.coinWrapperLg .coin {
  position: absolute;
  top: -130px;
  right: 0;
  max-width: 80%;
  opacity: 0.4;
  z-index: -1;
  animation: upDown 5s infinite;
  transition: all 0.3s ease-in-out;
}

.road-map {
  background-image: url(../images/ripple.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  padding: 0;
  z-index: 10;
}

.purpleRipple {
  width: 100%;
  min-height: 100vh;
  background-image: url(../images/white-rounds.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  padding: 60px 0px 60px 0px;
}

.customTabs {
  background-color: #fff;
  box-shadow: 0 4px 4px 0 #00000025;
  border-radius: 15px;
  padding: 2rem 1rem;
  color: #bcbcbc;
}

.customTabs .nav-pills .nav-link.active,
.customTabs .nav-pills .show>.nav-link {
  background-color: #7235cd;
  color: #fff;
}

.customTabs .nav-pills .nav-link {
  color: #626262;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.customTabs .tab-content {
  font-size: 1.2rem;
  line-height: 1.8;
}

.road-map .coin {
  position: absolute;
  left: -30%;
  bottom: -90%;
  width: 100%;
  opacity: 0.3;
  z-index: -1;
  animation: upDown 5s infinite;
  transition: all 0.3s ease-in-out;
}

.text-purple {
  color: #7235cd;
}

.accordion button:focus,
.accordion button:focus,
.accordion-button:not(.collapsed) {
  box-shadow: 0 0 4px 0 #00000025;
  background-color: #fff;
  color: #626262;
}

.accordion-item {
  border: none;
}

.accordion-item button {
  border-radius: 8px !important;
  box-shadow: 0 0 4px 0 #00000025;
  color: #111111;
  padding: 1.25rem 1.5rem;
}

.accordion-button::after {
  display: none;
}

.accordion-item {
  margin-bottom: 1rem;
}

.margin-100 {
  margin-top: 100px;
}

.margin-200 {
  margin-bottom: 100px;
}

.margin-170 {
  margin-top: 170px;
}

.road-map .coin-right {
  position: absolute;
  right: 0;
  bottom: -70px;
  width: 300px;
  opacity: 0.3;
  z-index: -1;
  animation: upDown 5s infinite;
  transition: all 0.3s ease-in-out;
}

footer {
  position: relative;
  margin-top: 180px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  background: #7235cd;
  /* background-image: url(../images/footer.png); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

footer .logo-w {
  max-width: 200px;
}

footer p {
  max-width: 600px;
  margin: auto;
  font-size: 0.82rem;
  font-weight: 300;
}

footer .copright {
  border-top: 1px solid #ffffff;
  padding: 2rem 0;
}

footer .copright p {
  font-size: 0.9rem;
}

footer .social {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

footer .social .iconWrapper img {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 0 0.5rem;
}

.bannerImg {
  display: flex;
  align-items: flex-start;
}

.bannerImg .phone {
  width: 100%;
  z-index: 2;
  animation: upDownPhone 5s infinite;
  transition: all 0.3s ease-in-out;
}

.bannerImg .graph2 {
  width: 100%;
  padding: 20px 20px 20px 20px;
  background-color: #313131;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  margin-left: -30px;
  z-index: 2;
  align-self: flex-end;
  margin-bottom: 60px;
}

.bannerImg .graph3 {
  width: 100%;
  margin-right: -30px;
  z-index: 3;
}

.bannerImg>div:last-child {
  align-self: flex-end;
}

.bannerImg>div:first-child {
  z-index: 3;
}

.visible {
  transition: all 1s ease-in-out;
  transform: translateY(30px);
  opacity: 0;
}

.visible.active {
  transform: translateY(0);
  opacity: 1;
}

.visible-left {
  transition: all 1s ease-in-out;
  transform: translateX(-30px);
  opacity: 0;
}

.visible-left.active {
  transform: translateX(0);
  opacity: 1;
}

.visible-right {
  transition: all 1s ease-in-out;
  transform: translateX(30px);
  opacity: 0;
}

.visible-right.active {
  transform: translateX(0);
  opacity: 1;
}

.ocean {
  position: relative;
  overflow: hidden;
  height: 100px;
  margin-top: -100px;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%237235cd'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100px;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  top: 0;
  left: 0;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

.coin-mb {
  display: none;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0px);
  }

  33% {
    transform: translateY(20px);
  }

  66% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes upDownCoin {
  0% {
    bottom: -110px;
  }

  100% {
    bottom: -90px;
  }

  /* 100% {bottom: -110px;} */
}

@keyframes upDownPhone {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0px);
  }

  /* 100% {bottom: -110px;} */
}

@media (max-width: 786px) {
  .appLogo {
    height: 35px;
  }

  .top {
    margin-top: 65px;
  }

  .banner {
    min-height: 100vh;
    padding: 80px 0px 100px 0px;
  }

  .top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    padding: 80px 0px 0px 0px;
  }

  .bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .counterWrapper {
    margin-top: 0;
  }

  .customCard {
    flex-direction: column;
  }

  .customCard p {
    order: 1;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 0;
  }

  .customCard img {
    order: 2;
    max-width: 100%;
  }

  .bottom .col-10:last-child {
    justify-self: flex-end;
    margin-left: auto;
  }

  .bannerImg {
    margin-top: 180px;
  }

  .why .curve {
    top: -14px;
    background-size: 100%;
  }

  .heading-lg {
    font-size: 2rem;
    font-weight: 700;
  }

  .coin1 {
    width: 200px;
    left: unset;
    right: 0;
    top: -100px;
  }

  .digitList {
    flex-wrap: wrap;
  }

  .digitList>div {
    width: calc(50% - 15px);
  }

  .digitList .dot {
    /* transform: translateY(-10px); */
    margin-left: 10px;
  }

  .digitBox {
    justify-content: center;
  }

  .singleDigit:last-child {
    margin-right: 0;
  }

  .singleDigit {
    font-size: 2rem;
    width: 68px;
  }

  .verto .greyBox:nth-child(odd),
  .verto .greyBox:nth-child(even) {
    margin: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .verto .greyBox img {
    margin-right: 0;
    width: 70px;
  }

  .greyBox .title {
    font-size: 1.5rem;
    padding: 0 0.25rem;
  }

  .greyBox .desc {
    font-size: 1rem;
  }

  .coin2 {
    bottom: unset;
    top: 50%;
    left: -113px;
    width: 230px;
  }

  .coin3 {
    display: none;
  }

  .coin-mb {
    display: block;
    bottom: -25px;
    top: unset;
    left: calc(50% - 26px);
  }

  .verto-coin {
    top: -209px;
    left: 60px;
  }

  .gridRow {
    margin: 2rem 0 5rem 0;
  }

  .gridRow img {
    margin: 3rem 0;
  }

  .coin-image {
    max-width: 190px;
    margin: auto;
  }

  .about {
    padding-bottom: 0;
  }

  .coinWrapperSm .coin {
    display: none;
  }

  .small {
    font-size: inherit;
  }

  .coinWrapperLg .coin {
    display: none;
  }

  .road-map {
    background-size: 264%;
    padding-top: 0;
  }

  .customTabs {
    width: calc(100% - 30px);
    margin: auto;
    margin-top: 3rem;
  }

  .customTabs .tab-content {
    font-size: 1rem;
    line-height: 1.8;
    margin-top: 2rem;
  }

  .margin-100 {
    margin-top: 50px;
  }

  .accordion-body {
    font-size: 1rem;
  }

  .accordion-button {
    font-size: 1.1rem;
  }

  .coin-right {
    display: none;
  }

  .digitList .dot:nth-child(4) {
    display: none;
  }

  .tech {
    overflow: hidden;
  }

  .navbar {
    background-color: #fff !important;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }
}

/* -----------------------LOGIN----------------------- */
.Login .screenWrapper {
  /* padding-top: 80px; */
  background-color: #F5F5F5;
  /* height: 100vh; */


}

.bgImage1 {
  background-image: url("/src/assets/images/Ellipse\ 1.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  height: 100%;
  top: 0;
  right: 0;
  width: 50%;
  /* opacity: 0.2; */
  filter: blur(50px);

}

.bgImage2 {
  background-image: url("/src/assets/images/Ellipse\ 2.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  height: 100%;
  left: 0;
  bottom: 0;
  width: 50%;
  /* opacity: 0.2; */
  filter: blur(50px);

}

.authWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  height: 100%;
  /* height: 100vh; */
}

.authWrapper .form {
  background-color: white;
  padding: 60px 80px;
  box-shadow: 0px 0px 5px #ffffff;
  border-radius: 10px;
}

.AccountRecoverySize {
  /* width: 647px; */
}



.authWrapper .authParaText {
  font-family: Lucien;
  font-size: 65px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: center;
  color: #1368BD;

}

.loginForm {
  padding: 40px 0;
}

.loginForm :is(.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root).Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--black);
}

.loginForm .inputStyles label.Mui-focused {
  color: var(--black);
}

.loginForm :is(.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root).Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--error);
  border-width: 1.5px;
}

.loginForm .inputStyles label.Mui-error {
  color: var(--error);
}

#outlined-required-helper-text,
#filled-weight-helper-text {
  color: var(--error);
  font-size: 12px;
}

.formInnerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formInnerContent :is(span, a) {
  color: var(--erieB);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20px;
  /* line-height: 30px; */
}

.rememberMeWrapper span {
  padding-right: 5px;
  font-family: 'Heebo', sans-serif;
}

.formInnerContent a.forgotText {
  color: #0D99E1;
  text-decoration: none;
  font-family: 'Heebo', sans-serif;
}

.inputStyles :is(label, input) {
  /* font-size: 20px;
  font-family: 'Heebo', sans-serif;
  font-weight: 400; */
}

.dontHaveAccWrapper {
  display: flex;
  justify-content: center;
}

.dontHaveAccWrapper :is(span) {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Heebo', sans-serif;
}

.dontHaveAccWrapper :is(a) {
  font-size: 18px;
  font-weight: 700;
}

.loginForm .inputStyles label {
  /* background-color: var(--primary); */
  padding-right: 10px;
  color: #C7CAD1;
}

.Login .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 2px;
}

/* -----------------------LOGIN----------------------- */

/* -----------------------ACCOUNT RECOVERY----------------------- */
.AccountRecovery .dontHaveAccWrapper :is(a) {
  border-bottom: 1px solid var(--white);
  margin-left: 5px;
}

.widthAuto {
  width: auto !important;
}

.AccountRecovery .authParaText {
  padding: 50px 0;
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1368BD;
}

/* -----------------------ACCOUNT RECOVERY----------------------- */

/* -----------------------CREATE NEW PASSWORD----------------------- */
.CreateNewPassword .errorMsg {
  position: absolute;
}

.CreateNewPassword .authParaText {
  padding: 50px 0;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #828282;


}

.CreateNewPassSize {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #DCE5F3;
  border-radius: 9px;
}

.DonePageSize {
  /* width: 647px; */
  /* height: 378px; */
  border-radius: 9px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #DCE5F3;
  border-radius: 9px;
}



/* -----------------------CREATE NEW PASSWORD----------------------- */

/* -----------------------REGISTER----------------------- */
.Register .inputStyles {
  margin: 10px 0
}

#outlined-select-currency {
  font-size: 14px;
}

.numberWrapper {
  display: flex;
  border: none;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 2px;
}

.numberWrapper fieldset {
  border: none;
}

.numberWrapper .languageWrapper {
  padding: 16.5px 14px;
}

.numberWrapper .custom-select-wrapper.open-dropdown ul {
  width: fit-content;
}

.numberWrapper .MuiTextField-root.inputStyles {
  margin: 0
}

.purchaseText.css-13naouh-MuiTypography-root {
  font-size: 2rem;
  padding-top: 40px;
}

.Register .currencyWrapper .amount .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {}

.Register .currencyWrapper .amount {
  border: none
}

.Register .currencyWrapper .amount input {
  height: 1.47rem;
}

.Register .currencyWrapper .amount fieldset {
  top: 0;
}

.Register .rememberMeWrapper span,
.bottomContentWrapper {
  font-size: 18px;
}

.bottomContentWrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* padding-top: 5px; */
}

.bottomContentWrapper span {
  /* color: var(--erieBlack);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 140%;
  padding: 3px 0 */
}

.Register .currencyWrapper :is(.css-13qq2og .MuiTextField-root) {
  margin: 0
}

.Register .currencyWrapper .amount legend {
  display: none;
}

.Register .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--black);
}

.loginContainer {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.loginButton {
  display: flex;
  justify-content: center;
}

/* -----------------------REGISTER----------------------- */
.RegisterSize {
  height: 100vh;
  background-color: white;
  padding: 0px 40px;
  box-shadow: 0px 0px 5px #ffffff;
  border-radius: 10px;

}

.socialIcon.drawer {
  display: flex;
  justify-content: center;
  padding: 0 0 40px
}

.formInnerContent a.forgotText.registerNowText {
  font-family: 'Lucien';
}




.Login .AppScreensButton {
  margin: 0 auto;
  display: flex;
  align-self: center;
  padding: 14px 72px;
  gap: 10px;
  background-color: #1368BD;
  transition: background-color .5s ease;
  font-size: 20px;
}

.Login .AppScreensButton:hover {
  background-color: #fff;
  color: #1368BD;
}

.AppScreensButton {
  margin: 0 auto;
  display: flex;
  align-self: center;
  padding: 16px 72px 16px 72px;
  gap: 10px;
  background-color: #1368BD;
}