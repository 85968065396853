@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  height: 100%;
}

html,
body,
#root,
.App {
  box-sizing: border-box;
  margin: 0;
  height: 100%;
}

.Router,
.Login {
  flex: 1;
  display: flex;
}

.Router {
  flex-direction: column;
}

.Login,
.contentContainer,
.screenWrapper {
  /* height: 100%; */
}

@font-face {
  font-family: Lucien;
  src: url('../fonts//TT1142M_.ttf');
}


:root {
  --primary: #73D3EA;
  --lightPrimary: rgba(115, 211, 234, 0.95);
  --black: #000000;
  --white: #ffffff;
  --midBlack: #020202;
  --lightWhite: rgba(255, 255, 255, 0.9);
  --lightBlack: rgba(0, 0, 0, 0.9);
  --tealBlue: #1E6778;
  --lightTealBlue: rgba(30, 103, 120, 0);
  --gray: #575757;
  --smokeWhite: #F5F5F5;
  --antiFlashwhite: #ECEEEE;
  --pacificBlue: #139ABF;
  --middleBlue: #73D3EA;
  --gainsBoro: #D9D9D9;
  --davyGray: #575757;
  --error: #B71923;
  --erieBlack: #1D1D1D;
  --orange: #FFBF28;
  --green: #56E79B;
  --pink: #FE95B4;
  --isabelline: #F9F4F1;
  --neonPink: #F0416F;
}


*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #E4FBFF;
}

*::-webkit-scrollbar-thumb {
  background-color: #2e75ea;
  outline: 1px solid #2e75ea;
}

.css-eeowg1-MuiPaper-root {
  height: 100%;
}

/* -----------------------GLOBAL----------------------- */
.emptyWrapper {
  display: flex;

}

.flex {
  display: flex;
  justify-content: center;
}

.bgSmokeWhite {
  background-color: var(--smokeWhite);
}

.contentContainer {
  width: 100%;
  z-index: 2;
  position: relative
}

.screenWrapper {
  /* background-image: linear-gradient(180deg, var(--tealBlue) 8%, var(--lightTealBlue) 92.19%);  */
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 25px;
  /* background-image: linear-gradient(180deg, rgba(115, 211, 234, 0.45) 5%, rgba(115, 211, 234, 0.45) 92.19%), url('./imgs/bgMainImg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100%; */
  flex: 1;
}

.video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.Login.WaveMap .contentWrapper {
  display: none;
  padding: 70px;
}

/* -----------------------GLOBAL----------------------- */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ COMPONENTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* -----------------------CHART----------------------- */
@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }

  to {
    opacity: 1;
  }
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 1ms;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor-expand>div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}

.chartjs-size-monitor-shrink>div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}

/* -----------------------CHART----------------------- */

/* -----------------------APP HEADER----------------------- */
.relativeHeader {
  transition: all 0.5s;
}

.navbarContainer {
  background-color: var(--lightWhite)
}

.stickyHeader .navbarContainer {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  right: 0;
}

.navLink {
  color: var(--black);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.navLink:hover {
  color: var(--primary)
}

.navLink:hover .underline {
  border-color: var(--primary)
}

.underline {
  border-bottom: 1px solid var(--black);
  width: auto;
  top: -3px;
  position: relative
}

.navBarIcon {
  display: none;
}

.navbar-light .navbar-toggler {
  border: none;
  box-shadow: none;
  padding-right: 0;
}

.offcanvas .navLink {
  padding: 0.5rem 0;
}

.offcanvas .navLink:hover {
  color: var(--white);
}

.offcanvas-header {
  padding: 40px 30px;
  background-color: var(--primary);
}

.offcanvas-header img {
  height: 35px;
}

.offcanvas .footerText {
  font-size: 12px;
}

.offcanvas-body {
  padding: 0 40px 30px;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offcanvas-header .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
  margin: 0;
  padding: 0;
  width: 12px;
}

.drawer {
  justify-content: flex-start;
  padding: 20px 0 0;
}

.drawer .icon {
  color: var(--black);
  font-size: 24px;
  padding-left: 0;
  padding-right: 25px;
  transition: all 0.5s linear;
}

.drawer .icon:hover {
  color: var(--white);
}

.navbar-light .navbar-toggler-icon {
  /* background-image: url('./imgs/navbar_Icon.png'); */
}

.offcanvas.show {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.relativeHeader,
.stickyHeader {
  padding: 0;
}

#basic-navbar-nav {
  display: none;
}

.languageWrapper {
  display: flex;
  align-items: center;
  padding: 0px 25px 25px;
}

.language {
  color: var(--black);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  display: block;
  padding: 0 10px;
  text-decoration: none;
  font-size: 14px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.flag {
  height: 15px;
  width: 25px;
}

.custom-select-wrapper {
  background-color: var(--white);
  width: 50%;
  box-shadow: 9px 10px #00000052;
  list-style: none;
  padding: 0;
  text-align: center;
  height: 0;
  overflow: hidden;
  margin: 0;
  transition: 0.1s ease-out;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}

.custom-select-wrapper ul li:last-child {
  padding-bottom: 0;
}

.custom-select-wrapper.open-dropdown ul {
  padding: 0 15px;
  height: 180px;
  overflow: auto;
  transition: 0.1s ease-in;
  border-radius: 4px;
}

.custom-select-wrapper ul:hover {
  cursor: pointer;
}

.custom-select-wrapper {
  position: relative;
  cursor: pointer;
}

/* -----------------------APP HEADER----------------------- */

/* -----------------------APP HEADER 2----------------------- */
.AppHeader2Wrapper {
  background-image: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppHeader2Wrapper .contentWrapper {
  padding: 50px;
  width: 100%;
}

/* -----------------------APP HEADER 2----------------------- */

/* -----------------------APP LOGO HEADER----------------------- */
.AppLogoHeader .navbarContainer {
  padding: 15px 0;
}

/* -----------------------APP LOGO HEADER----------------------- */

/* -----------------------APP SCREEN HEADER----------------------- */
.sideBar {
  flex: 0.7;
  /* background-color: var(--primary); */
  background-color: "transparent";
  /* padding: 25px 0; */
  z-index: 1;
}

.sideBar img.logo {
  height: 80px;
  margin-bottom: 40px;
}

.sideBar img.userImg {
  height: 80px;
  width: 80px;
  border-radius: 100px;
}

.sideBar span {
  font-size: 16px;
  font-weight: 700;
  color: var(--erieBlack);
}

.sideBar ul {
  width: -webkit-fill-available;
  padding: 20px 0;
}

.sideBar ul a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 25px 0 5rem;
  margin: 5px 0;
  position: relative;
  z-index: 1;
  transition: .35s ease color
}

.sideBar ul a img,
.sideBar .signOut a img {
  height: 20px;
}

.sideBar .navLink div.imageWrapper {
  /* width: 50px; */
  width: 40%;
  align-items: flex-end;
  padding-right: 20px;
}

.sideBar ul a span,
.sideBar .signOut a span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  border-top: 2px solid #C9F9FF;
  padding: 30px 0px;
}

.sideBar ul a:first-child span {
  border: none;
}

.sideBar div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.sideBar ul a:before {
  /* content: '';
  display: block;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-left: solid 5px #1368BD;
  border-width: 0;
  background: rgba(19, 104, 189, 0.5);
  transition: .35s ease width; */
}

.sideBar ul a.is-current:before,
.sideBar ul a:hover:before {
  /* right: 0;
  width: 100%;
  border-width: 5px; */
}

.sideBar .navLink.active span,
.sideBar .navLink.active {
  /* border-right: 5px solid #1368BD; */
}


.sideBar ul a:hover span {
  /* color: var(--white); */
}

/* .sideBar .signOut {
  width: -webkit-fill-available;
  padding-top: 100px;
}

.sideBar .signOut a {
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--erieBlack);
}

.sideBar .signOut a span {
  transition: color .15s ease-in-out;
  font-size: 15px;
}

.sideBar .signOut a:hover span {
  color: var(--white);
} */

.offcanvas .sideBar ul a.navLink.active {
  padding: 10px 35px;
}

.sideBar div:first-child .sideBarFirstWrapper {
  justify-content: flex-start;
  width: 100%;
}

/* -----------------------APP SCREEN HEADER----------------------- */

/* -----------------------APP SCREEN HEADER 2----------------------- */
.AppScreensHeader2Canvas .offcanvas-header {
  background-color: var(--white);
}

.AppScreensHeader2Canvas .offcanvas-body {
  padding: 0;
}

/* -----------------------APP SCREEN HEADER 2----------------------- */

/* -----------------------APP FOOTER----------------------- */
.AppFooter .whiteSpaceBox {
  padding: 25px;
  background-color: var(--white);
  z-index: 1
}

.AppFooter .mainContainer {
  background-color: var(--primary);
  padding: 25px 0;
  z-index: 1;
}

.AppFooter .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.AppFooter .image {
  margin-bottom: 20px
}

.footerWrapper {
  background-color: var(--white);
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.footerWrapper .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.footerText {
  margin: 0;
  padding: 0;
  padding-left: 2px;
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  font-family: 'Manrope', sans-serif;
}

.AppFooter .socialIconWrapper {
  padding-top: 33px
}

.AppFooter .icon {
  color: var(--black);
  font-size: 25px;
  padding: 0;
  transition: 0.5s;
  z-index: 2;
  position: relative;
}

.AppFooter .socialIconWrapper span:hover .icon {
  color: var(--white);
  transform: scale(1.1);
}

.socialIconWrapper span {
  margin: 0 0px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  line-height: 50px !important;
  text-align: center;
  color: snow;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.socialIconWrapper span:hover {
  opacity: 1;
  box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.3);
}

.socialIconWrapper span:before {
  position: relative;
  z-index: 1;
}

.socialIconWrapper .after-span {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.75s ease-in;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
}

.socialIconWrapper span:nth-of-type(1) .after-span {
  background-color: #4267B2;
}

.socialIconWrapper span:nth-of-type(2) .after-span {
  background: #00acee;
}

.socialIconWrapper span:nth-of-type(3) .after-span {
  background-color: #0072b1;
}

.socialIconWrapper span:hover .after-span.ripple {
  transform: scale(1);
  transition: transform 1s ease-out;
}

/* -----------------------APP FOOTER----------------------- */

/* -----------------------APP TEXT IMAGE GRID----------------------- */
.AppTextImageGrid {
  background-color: var(--smokeWhite);
}

.textImgGridContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  margin: 0 auto;
  width: calc(100% * 0.85);
}

:is(.textGridWrapper, .imgGridWrapper) {
  width: 50%;
}

.imgGridWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgGridWrapper .image {
  width: 180px;
  height: 180px;
}

/* -----------------------APP TEXT IMAGE GRID----------------------- */

/* -----------------------APP BUTTON----------------------- */
.AppButton {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  border-width: 2px;
  border-radius: 4px;
  width: 200px;
  padding: 10px 0;
  outline: none;
  transition: all 1s ease;
  margin: 0px 10px;
  position: relative;
  display: inline-block;
}

.AppButtonFill {
  background-color: var(--primary);
  color: var(--black);
  border: none;
  box-shadow: inset 2px 2px 2px 0px rgba(30, 117, 137, .5),
    7px 7px 20px 0px rgba(30, 117, 137, .1),
    4px 4px 5px 0px rgba(30, 117, 137, .1);
}

.AppButtonOutline {
  background-color: var(--black);
  border: none;
  color: var(--white);
  box-shadow: inset 2px 2px 2px 0px rgba(101, 90, 90, .5),
    7px 7px 20px 0px rgba(101, 90, 90, .1),
    4px 4px 5px 0px rgba(101, 90, 90, .1);
}

.AppButton:hover {
  cursor: pointer;
  transform: scale(1.25);
  color: var(--white);
}

.AppButtonFill:hover {
  background-color: var(--primary);
  box-shadow: inset 2px 2px 2px 0px rgba(115, 211, 234, .5),
    7px 7px 20px 0px rgba(115, 211, 234, .1),
    4px 4px 5px 0px rgba(115, 211, 234, .1);
}

.AppButtonOutline:hover {
  background-color: var(--black);
  box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
}

.AppButton:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  border-radius: 4px;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  transition: all 0.7s ease;
  box-shadow:
    -7px -7px 20px 0px #eee9,
    -4px -4px 5px 0px #eee9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;

}

.AppButton:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.AppButton:active {
  top: 2px;
}

/* .AppScreensButton,
.AppScreensButton:is(:active, :focus, .active) {
  border: 3px solid var(--white);
  font-family: 'Poppins, sans-serif';
  font-style: normal;
  font-weight: 700;
  background-color: transparent;
  font-size: 20px;
  line-height: 30px;
  width: 184px;
  transition: 0.5s ease;
  box-shadow: none
} */

/* .AppScreensButton:hover {
  background-color: var(--black);
  border-color: var(--black);
} */

/* -----------------------APP BUTTON----------------------- */

/* -----------------------APP COUNT DOWN----------------------- */
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter .itemWrapper.danger {
  color: #ff0000;
}

.show-counter .itemWrapper>p {
  margin: 0;
}

/* -----------------------APP COUNT DOWN----------------------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ COMPONENTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ANIMATIONS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* -----------------------TEXT ANIMATION----------------------- */
.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* -----------------------TEXT ANIMATION----------------------- */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ANIMATIONS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SCREENS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.space {
  padding: 10px;
}

/* -----------------------HOME----------------------- */
.separator {
  height: 100%;
  width: 3px;
  background-color: var(--gray);
  margin: 0 40px;
  border-radius: 2px
}

.name {
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--black);
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px
}

.itemWrapper:hover {}

.contentWrapper {
  /* background: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%), url('./imgs/bgMainImg.png'); */
  padding: 50px 0;
  z-index: 2
}

.paraWrapper {
  background-color: var(--primary);
  margin: 25px auto;
  padding: 8px 30px;
}

.btnWrapper {
  padding: 20px 0;
}

.counterContainer {
  padding: 40px 0
}

.counterWrapper {
  /* background: linear-gradient(180.6deg, var(--white) 0.52%, rgba(255, 255, 255, 0) 126.23%); */
  /* border-radius: 19px; */
  margin: 0 auto;
  /* padding: 20px 40px */
}

.counterText {
  text-align: center;
  font-size: 40px;
  font-family: 'Manrope', sans-serif;
  color: var(--black);
  font-weight: 700
}

.presaleCounter .counterWrapper {
  /* position: absolute;
  z-index: 2;
  padding: 0;
  margin-top: 20px;
  background-image: linear-gradient(180.6deg, #2e2f3380 0 100%);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
}

.presaleCounter .separator {
  margin: auto;
  width: 2px;
  height: 140px;
  background-color: var(--white)
}

.presaleCounter .itemWrapper {
  width: auto;
  padding: 20px 75px;
  align-items: center;
}

.presaleCounter .itemWrapper .name {
  text-align: left;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  color: #FFFFFF;
}

.presaleCounter .itemWrapper .counterText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  color: #FFFFFF;
}

.contentWrapper h1 {
  --fill-color: var(--white);
  position: relative;
  display: block;
  padding: 4px 0;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-text-stroke: 2px var(--fill-color);
  background: linear-gradient(45deg, var(--tealBlue) 10%, var(--primary) 90%) left / 0 no-repeat;
  color: transparent;
  background-clip: text;
  transition: 0.7s linear;
}

.contentWrapper h1:hover {
  background-size: 100%;
  -webkit-text-stroke: 2px transparent;
}

/* -----------------------HOME----------------------- */

/* -----------------------ABOUT US----------------------- */
.aboutUsWrapper {
  z-index: 2;
  padding: 0;
}

.AboutUs .contentWrapper {
  /* background-image: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%), url('./imgs/aboutUsBgImg.png'); */
  /* background-attachment: fixed;  */
  background-repeat: no-repeat;
  background-size: cover;
}

.AboutUs .paraWrapper {
  margin: 0 auto
}

.AboutUs .lightPrimryWrapper {
  /* background-image: linear-gradient(180deg, var(--lightPrimary) 5%, var(--lightPrimary) 92.19%), url('./imgs/aboutUsBgImg2.png'); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.lightPrimryWrapper .wrapper {
  padding: 50px 0;
  width: calc(100% * 0.75);
  margin: 0 auto;
}

.AboutUs .gridBox {
  width: 100%;
  padding-top: 50px
}

.imgGridWrapper img {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

.imgGridWrapper img {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* -----------------------ABOUT US----------------------- */

/* -----------------------WAVE MAP----------------------- */
.waveMapWrapper {
  background-color: var(--smokeWhite);
  z-index: 2;
  padding: 50px 0;
}

.WaveMap .contentWrapper {
  /* background-image: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%), url('./imgs/waveMapBgImg.png'); */
  /* background-attachment: fixed;  */
  background-repeat: no-repeat;
  background-size: cover;
}

.waveMapImageWrapper {
  display: flex;
  justify-content: center;
}

.waveMapImage {
  height: 350px;
}

/* -----------------------WAVE MAP----------------------- */

/* -----------------------TOKENOMICS----------------------- */
.tokenomicsWrapper {
  background-color: var(--smokeWhite);
  z-index: 2;
  padding: 0;
}

.Tokenomics .contentWrapper {
  /* background-image: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%), url('./imgs/tokenomicsBgImg.png'); */
  /* background-attachment: fixed;  */
  background-repeat: no-repeat;
  background-size: cover;
}

.tokenomicsHeader .contentWrapper {
  /* background-image: linear-gradient(183.03deg, rgba(115, 211, 234, 0.95) 7.4%, rgba(115, 211, 234, 0) 130.74%), url('./imgs/tokenomicsBgImg2.png'); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.tokenomicsWrapper .tokenomicsHeader {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tokenomicsGrid {
  position: relative;
  padding: 30px 20px 20px;
  border: 1px solid var(--black);
  display: flex;
  justify-content: center;
}

.tokenomicsWrapper .textImgGridContainer {
  flex-wrap: wrap;
  width: calc(100% * 0.65);
}

.tokenomicsWrapper .tokenomicsGridTitle {
  font-size: 20px;
}

.tokenomicsGrid .paraWrapper {
  position: absolute;
  top: -20px;
  margin: 0;
  width: 80%;
  padding: 8px 0;
}

.tokenomicsWrapper .textImgGridContainer .flex {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.gridWrapper {
  margin: 30px auto 20px;
}

.percentageWrapper {
  background-color: var(--antiFlashwhite);
  margin: 50px 0;
}

.pieChartImg {
  height: 300px;
  position: absolute;
}

.percentageWrapper .textImgGridContainer {
  display: flex;
  justify-content: center;
  height: 200px;
}

/* -----------------------TOKENOMICS----------------------- */

/* -----------------------LOGIN----------------------- */
.Login .screenWrapper {
  /* padding-top: 80px */
  align-items: center;
  justify-content: center;
  display: flex;
}

.authWrapper {
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
}

.authWrapper .form {
  flex: 1;
  border-radius: 9px;
  box-shadow: 0px 4px 4px #dce5f3;
  /* background-color: var(--primary);
  padding: 80px 100px; */
}

.authWrapper .authParaText {
  /* text-align: left;
  padding-top: 5px;
  font-size: 16px;
  padding-left: 5px; */
}

.loginForm {
  /* padding: 60px 0; */
}

.loginForm :is(.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root).Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--black);
}

.loginForm .inputStyles label.Mui-focused {
  color: var(--black);
}

.loginForm :is(.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root).Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--error);
  border-width: 1.5px;
}

.loginForm .inputStyles label.Mui-error {
  color: var(--error);
}

#outlined-required-helper-text,
#filled-weight-helper-text {
  color: var(--error);
  font-size: 12px;
}

.formInnerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formInnerContent :is(span, a) {
  color: var(--black);
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.rememberMeWrapper span {
  padding-right: 5px;
  padding-top: 4px;
}

.rememberMeWrapper img {
  height: 18px;
}

.formInnerContent a.forgotText {
  color: #53B1E6;
  text-decoration: none;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.formInnerContent a.forgotText:hover {
  color: #0a58ca;
}


.inputStyles :is(label, input) {
  font-size: 18px;
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
}

.inputStyles :is(input) {
  padding: 10px 0;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before,
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after,
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-color: #EEEEEE !important;
}




.dontHaveAccWrapper {
  padding: 40px 0;
  justify-content: flex-start;
}



.dontHaveAccWrapper :is(a) {
  font-size: 16px;
  font-weight: 700;
}

.loginForm .inputStyles label {
  /* background-color: var(--primary);
  padding-right: 10px; */
}

.Login .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 2px;
}

.Login.WaveMap .contentWrapper {
  /* background-image: linear-gradient(180deg, var(--tealBlue) 5%, var(--lightTealBlue) 92.19%), url('./imgs/bgImage.png'); */
  /* background-attachment: fixed;  */
  background-repeat: no-repeat;
  background-size: cover;
}

/* -----------------------LOGIN----------------------- */

/* -----------------------ACCOUNT RECOVERY----------------------- */
.AccountRecovery .dontHaveAccWrapper {
  justify-content: center;
  font-weight: 600;
}

.AccountRecovery .dontHaveAccWrapper :is(a) {
  border-bottom: 1px solid var(--white);
  margin-left: 5px;
  color: #289EE0;
}

.widthAuto {
  width: fit-content !important;
}

.AccountRecovery .authParaText {
  padding: 50px 0;
}

/* -----------------------ACCOUNT RECOVERY----------------------- */

/* -----------------------CREATE NEW PASSWORD----------------------- */
.CreateNewPassword .errorMsg {
  position: absolute;
}

.CreateNewPassword .authParaText {
  padding: 50px 0;
}

/* -----------------------CREATE NEW PASSWORD----------------------- */

/* -----------------------REGISTER----------------------- */
.Register .inputStyles {
  margin: 12px 0
}

#outlined-select-currency {
  font-size: 14px;
}

.numberWrapper {
  /* display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 2px; */
}

.numberWrapper fieldset {
  border: none;
}

.numberWrapper .languageWrapper {
  padding: 16.5px 14px;
}

.numberWrapper .custom-select-wrapper.open-dropdown ul {
  width: fit-content;
}

.numberWrapper .MuiTextField-root.inputStyles {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgb(188 195 184 / 42%);
}

.purchaseText.css-13naouh-MuiTypography-root {
  font-size: 2rem;
  padding-top: 40px;
}

.Register .currencyWrapper .amount .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {}

.Register .currencyWrapper .amount {
  border: none
}

.Register .currencyWrapper .amount input {
  height: 1.47rem;
}

.Register .currencyWrapper .amount fieldset {
  top: 0;
}

.Register .rememberMeWrapper span,
.bottomContentWrapper {
  /* font-size: 12px; */
}

.bottomContentWrapper {
  /* display: flex; */
  /* flex-direction: column; */
  /* padding-top: 50px; */
}

.bottomContentWrapper span {
  color: var(--black);
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  padding: 3px 0;
  font-size: 14px;
}

.Register .currencyWrapper :is(.css-13qq2og .MuiTextField-root) {
  margin: 0
}

.Register .currencyWrapper .amount legend {
  display: none;
}

.Register .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--black);
}

/* -----------------------REGISTER----------------------- */

/* -----------------------INNER SCREENS----------------------- */
.InnerScreens {
  display: flex;
  flex: 1;
  background-color: #ECFDFF;
  padding: 3rem 0 3rem 3rem;
}

.InnerScreens :is(.relativeHeader, .stickyHeader) {
  display: none;
}

.InnerScreens .contentContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  border-radius: 9px;
}

:is(.Profile, .BuyToken).InnerScreens .contentContainer {
  padding: 0;
}

.Profile.InnerScreens .contentContainer {
  background-color: transparent;
}

.InnerScreens .header {
  justify-content: flex-start;
  padding: 25px 0px;
  width: -webkit-fill-available;
  color: #1368BD;
  padding-left: 25px;
}

.InnerScreens .header h5 {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  font-family: 'Lucien', sans-serif;
  margin: 0;
}

.InnerScreens .screenWrapper {
  padding: 0 15px 20px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.whiteBgWrapper {
  /* border: 2px solid #F4FEFF; */
  border-radius: 9px;
  background-color: var(--white);
  padding: 25px;
}

.whiteBgWrapper p.title {
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--erieBlack)
}

/* -----------------------INNER SCREENS----------------------- */

/* -----------------------DASHBOARD----------------------- */
.chart {
  width: -webkit-fill-available;
}

/* -----------------------DASHBOARD----------------------- */

/* -----------------------BUY TOKEN----------------------- */
.BuyToken #outlined-select-currency {
  width: 100%;
}

form fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 2px;
}

form :is(.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: var(--erieBlack);
}

form .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--erieBlack);
}

.buyTokenForm {
  padding: 20px 0;
}

.fieldset {
  border: 1px solid #E4FBFF;
  background-color: #E4FBFF;
  border-radius: 10px;
  height: auto;
  min-height: 1.4375em;
  padding: 16.5px 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fieldset span {
  color: #289EE0;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.86px;
  padding-left: 30px;
}

.buyTokenForm .amountWrapper {
  margin: 30px 0;
  flex-wrap: wrap;
}

.buyTokenForm .amountWrapper div:first-child {
  display: flex;
  flex: 1
}

.buyTokenForm .amountWrapper .amount {
  align-items: center;
  padding: 0 10px;
}

.buyTokenForm .amountWrapper .amount span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--erieBlack);
  padding-right: 10px;
}

.buyTokenForm .amountWrapper .amount p {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.buyTokenForm .amountWrapper .amount p:first-child {
  font-weight: 600;
  padding-bottom: 5px;
}

/* .AppScreensButtonSecondary {
  background-color: var(--primary);
  color: var(--black);
  border-color: var(--primary);
  border-radius: 2px;
  margin-bottom: 40px;
} */

.buyTokenForm .formInnerContent {
  padding-top: 10px;
  justify-content: flex-start;
}

.buyTokenForm .formInnerContent2 {
  padding: 40px 0;
}

/* -----------------------BUY TOKEN----------------------- */

/* -----------------------TRANSACTIONS----------------------- */
.Transactions .dataTable {
  margin: 0;
}

.Transactions .dataTable :is(thead[data-test="datatable-head"], tbody[data-test="table-body"], .table>:not(caption)>*>*) {
  border: none
}

.Transactions div[data-test="datatable-entries"] {
  display: none !important;
}

.Transactions :is([data-test="table-foot"], .dataTables_info, .sr-only) {
  display: none;
}

.Transactions .dataTables_wrapper .row:first-child {

  position: absolute;
  top: 13px;
  padding: 0px 25px;
  height: 40px;
  left: 28px;

}

.Transactions .mdb-datatable-filter input::placeholder {
  color: #2B82D8;
}

.settingIconDiv {
  padding-right: 20px;
}

.Transactions .dataTables_wrapper .row:first-child [data-test="datatable-search"] {
  /* width: 100%; */
  justify-content: flex-start !important;
}

.Transactions .mdb-datatable-filter,
.Transactions .mdb-datatable-filter input {
  height: 100%;
  margin: 0 !important;
  border: none;
  background-color: #F4FEFF;
  color: #2B82D8;
}

.Transactions .wrapper {
  justify-content: space-between;
  align-items: center;
  border-right: 2px solid #F4FEFF;
  border-left: 2px solid #F4FEFF;
  border-top: 2px solid #F4FEFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #F4FEFF;
  padding: 15px 25px;

}

.settingIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid var(--erieBlack); */
  padding: 10px;

}

.settingIcon img {
  height: 24px;
}

.Transactions :is(th, td) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 18px 10px;
  vertical-align: middle;
}

.Transactions th {
  text-transform: uppercase;
  color: #0CA2D7;
}

.Transactions td:nth-child(4) {
  font-weight: 600;
}

.Transactions :is(th, td) {
  min-width: 150px;
}

.Transactions .whiteBgWrapper {
  position: relative;
}

.Transactions .dataTables_wrapper .row:nth-child(2) :is([class="dataTables_scroll"]) {
  /* border-right: 2px solid var(--erieBlack);
  border-left: 2px solid var(--erieBlack);
  border-bottom: 2px solid var(--erieBlack);
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px; */
  border: 1px solid #F4FEFF;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #F4FEFF;
  padding: 0 25px;
  overflow: hidden;
}

.Transactions .whiteBgWrapper {
  border: none;
  background-color: transparent;
  padding: 0;
  min-width: 100%;
}

.Transactions .dataTables_paginate .pagination {
  margin-top: 10px;
  justify-content: flex-end;
}

.Transactions :is(.page-item:first-child, .page-item:last-child) span {
  font-size: 14px;
  padding: 7px 14px;
  color: #fff;
  border: 1px solid #1368BD;
  background-color: #1368BD;
  font-weight: 600;
  border-radius: 14px;
  mix-blend-mode: normal;
}

.Transactions :is(.page-item:first-child, .page-item:last-child) span svg {
  position: relative;
  top: -2px;
}

.Transactions .page-item.disabled .page-link span {
  color: #fff;
  border: 1px solid #ACE0FD;
  background-color: #ACE0FD;
  font-weight: 600;
  border-radius: 14px;
  mix-blend-mode: normal;
}

.Transactions .page-link {
  color: #1368BD;
  border: none;
  padding: 10px 0.75rem;
  font-size: 12px;
}


.Transactions .page-item {
  display: flex;
  align-items: center;
}

.Transactions .page-item.active .page-link {
  color: #fff;
  background-color: #1368BD;
  border-color: var(--white);
  border-radius: 15px;
  padding: 3px 16px;
}

.Transactions .status {
  /* border: 1px solid var(--erieBlack); */
  padding: 5px 10px;
  width: 75%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.Transactions .pending {
  color: #1368BD;
}

.Transactions .canceled {
  color: #EB5757;
}

.Transactions .purchase {
  color: #06EAB3;
}

/* -----------------------TRANSACTIONS----------------------- */

/* -----------------------PROFILE----------------------- */
.Profile .tabs {

  border: 1px solid #1368BD;
  border-radius: 10px;
}

.Profile .whiteBgWrapper p.title {
  /* padding-bottom: 20px; */
  text-align: start;
  font-size: 30px;
  color: #1368BD;
  font-weight: 600;
  font-family: 'Lucien';

}

.profileForm {
  padding: 40px 0
}

.Profile .tab {
  font-family: 'Lucien';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1368BD;
  min-width: 150px;
}

.Profile .tab.Mui-selected {
  color: var(--white);
  font-weight: 600;
  background-color: #1368BD;
}

.Profile .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--black);
}

.Profile .inputStyles,
.Profile .inputStyles.marginFields,
.Profile .inputStyles.nationalityWrapper {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px;
  border: none;
  width: 30%;
  margin-right: 10%;
}


.twoFactorPara {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--erieBlack);
  mix-blend-mode: normal;
  opacity: 0.9;
}

.Profile .currentStatusWrapper {
  /* padding: 35px 0; */
  align-items: center;
  justify-content: space-between;
}

.Profile .currentStatusWrapper p {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
  color: #000;
  padding-right: 9px;

}

.Profile .currentStatusWrapper p.currentStatus {
  color: var(--neonPink);
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  font-family: 'Manrope', sans-serif;
}

.Profile .fieldset {
  justify-content: space-between;
  margin-bottom: 30px;
  background: none;
  border: none;
}

.Profile .fieldset img {
  height: 20px;
}

.firstWrapper {
  flex: 1;
}

.secondWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-left: 25px; */
  width: 100%;
  margin-top: 25px;
  /* margin-right: 25px; */
}

.secondWrapper .whiteBgWrapper:first-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 25px; */
  margin-right: 25px;
}

.secondWrapper .whiteBgWrapper:last-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 25px; */
  /* margin-left: 25px;  */
}

.Profile .numberWrapper .languageWrapperDiv {
  padding: 18px 10px;
  border-bottom: 1px solid rgb(188 195 184 / 42%);
}

/* -----------------------PROFILE----------------------- */

/* -----------------------MY TOKEN----------------------- */
.MyToken .screenFlexWrapper {
  width: -webkit-fill-available;
  flex-wrap: wrap;
}

.MyToken .whiteBgWrapper {
  padding: 35px;
  display: flex;
  background-color: #F4FEFF;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.MyToken .whiteBgWrapper {
  margin: 20px 0;
  background-color: #F4FEFF;
}

.MyToken .whiteBgWrapper .flex {
  justify-content: space-between;
  /* align-items: center; */
  /* padding: 40px 80px */
}

.InnerScreens .divider {
  border-right: 1px solid #9CE8F1;
  height: auto;
  left: unset;
  transform: unset;
  width: 0;
}

.MyToken .wrapper {
  padding: 0px;
  width: fit-content;

}

.MyToken .wrapper h2 {
  font-family: 'Lucien', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
  color: var(--erieBlack);
  margin: 0;
  padding-top: 5px;
}

.MyToken .wrapper h3 {
  font-family: 'Lucien', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: var(--erieBlack);
  margin: 0;
  padding-top: 8px;
}

.MyToken .wrapper h5 {
  font-family: 'Lucien', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--erieBlack);
  margin: 0;
  padding-top: 5px;
}

.MyToken .wrapper span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--erieBlack);
}

.MyToken .wrapper .AppScreensButtonSecondary {
  margin: 0;
  margin-top: 20px;
}

.TokenButton {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 100%;
  background: #1368BD;
  mix-blend-mode: normal;
  color: #FFFFFF;
  border-radius: 14px;
  margin: 0;
  font-size: 15px;
}

.group div:nth-child(2) {
  padding: 0 25px;
}

.BuyToken .emptyWrapper>.whiteBgWrapper {
  flex: 1;
}

.BuyToken .emptyWrapper,
.BuyToken.InnerScreens .screenWrapper {
  padding: 0
}

.whiteBgContainer {
  background-color: var(--white);
  border-radius: 10px;
  flex: 1
}

.sideBar .userImgBox {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #def8fc;
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 25px 3rem
}

.sideBar .userImgBox span {
  padding-left: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  display: block;
  color: #000000;
}

.sideBar .userImgBox span:nth-child(2) {
  padding-top: 10px;
}

.bImage1 {
  background-image: url("/src/assets/images/Ellipse\ 1.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  height: 100%;
  top: unset;
  bottom: 0;
  right: 0;
  width: 50%;
  filter: blur(50px);

}

.bImage2 {
  background-image: url("/src/assets/images/Ellipse\ 2.png");
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  height: 100%;
  left: 0;
  bottom: 0;
  width: 50%;
  filter: blur(50px);

}

.ProfileWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.InnerScreens .table>:not(caption)> {
  background-color: #F4FEFF;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.InnerScreens .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #E4FBFF;
}

.inputSize {
  display: flex;
  flex-direction: row;

}


/* -----------------------MY TOKEN----------------------- */

.numberWrapper .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-width: 0 !important;
}

.currencyWrapper p.css-1pnmrwp-MuiTypography-root {
  padding-right: 10;
  color: var(--black);
  font-size: 18px;
  font-weight: 500
}

label[data-shrink=false]+.MuiInputBase-formControl .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding: 10px 0;
}

.Register .icon {
  color: #1368BD;
  opacity: 0.3;
  transition: .5s ease;
  font-size: 28px;
  transform: scale(1);
}

.Register .icon:hover {
  opacity: 1;
  color: #1368BD;
  transform: scale(1.2);
  cursor: pointer;
}

.bottomContentWrapper span:nth-child(2) {
  padding-left: 30px;
}

.screenLogo {
  position: absolute;
  top: -10px;
  left: 50px;
}

.Login .dontHaveAccWrapper {
  justify-content: center;
}

.AccountRecovery .dontHaveAccWrapper {
  padding-bottom: 0;
}

.AccountRecovery .authParaText {
  padding: 30px 0;
  font-size: 16px;
  line-height: normal;
}

.CreateNewPassword .authParaText {
  font-size: 16px;
  line-height: normal;
}

.InnerScreens .table>:not(caption)>*>* {
  background-color: #F4FEFF;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.InnerScreens .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #E4FBFF;
}

.AppScreensHeader2 {
  display: none;
}

.AppScreensHeader2 .navbarContainer.navbar {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  backdrop-filter: unset;
  position: relative;
  padding: 10px 0;
}

.AppScreensHeader2 .mainContainer {
  position: relative;
}

.AppScreensHeader2 .custom-select-wrapper {
  position: absolute;
}

.AppScreensHeader2 #drawer.custom-select-wrapper.open-dropdown {
  margin: 0 auto;
  position: absolute;
  width: 95%;
  top: 100%;
  border-radius: 10px;
  background-color: #1368BD;
  height: auto;
  box-shadow: none;
}

.AppScreensHeader2 #drawer.custom-select-wrapper.open-dropdown ul {
  padding: 14px 7px;
  height: auto;
}

.AppScreensHeader2 #drawer.custom-select-wrapper.open-dropdown ul .navLink {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
}

.AppScreensHeader2 #drawer.custom-select-wrapper.open-dropdown ul .navLink .icon img {
  height: 18px;
}

.AppScreensHeader2 #drawer.custom-select-wrapper.open-dropdown ul .navLink .icon {
  width: 35px;
}

#language.custom-select-wrapper.open-dropdown {
  position: absolute;
  top: 12%;
  min-height: 200px;
}

.AppScreensHeader2 #language.custom-select-wrapper.open-dropdown ul {
  height: 200px;
}

.RecoveryNumberSize {
  flex: 1;
}

.ProfileButton {

  padding: 16px 72px;
  background: #1368BD;
  border-radius: 14px;
  border: none;
  font-weight: 700;
  color: white;
}

.ProfileButton2 {
  background: #1368BD;
  mix-blend-mode: normal;
  border-radius: 14px;
  padding: 16px 72px;
  font-weight: 700;
  border: none;
  color: white;
}

.ProfileButton3 {
  align-items: center;
  padding: 16px 0px;
  background: #1368BD;
  border-radius: 14px;
  border: none;
  font-weight: 700;
  align-items: flex-start;
  color: white;
  flex: 1;
  margin-right: 10px;
}

.secondWrapperPFlex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#languageWrapper.custom-select-wrapper {
  position: absolute;
}

.numberWrapper {
  position: relative;
}

#languageWrapper.custom-select-wrapper.open-dropdown {

  min-height: 180px;
  top: 100%;
}

#language .languageWrapper {
  padding: 10px;
}

#language .languageWrapper img {
  height: 18px;
  width: 26px;
}

.countdownBanner .row {}

.digitList {
  /* background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.shadowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.middle {
  background-image: url('../images/top-banner-02.png');
  padding: 0;
  min-height: auto;
  background-position: bottom;
  padding-top: 60px;
  margin-top: 60px;
}

.presaleCounter {
  background: #6C2CCB;
  border-radius: 90px;
  padding: 40px;
  margin: 0 auto;
}

.middle .heading-lg {
  color: var(--white);
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SCREENS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */